import _Vue from 'vue'
import { ServerRegistration } from '@/plugins/server-registration/server-registration'
import { RuntimeConfig } from '@/plugins/runtime-config/runtime-config'

declare module 'vue/types/vue' {
  interface Vue {
    $serverRegistration: ServerRegistration;
  }
}

export function ServerRegistrationPlugin(Vue: typeof _Vue, runtimeConfig: RuntimeConfig) {
  Vue.prototype.$serverRegistration = new ServerRegistration(runtimeConfig)
}

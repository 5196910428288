import axios, { AxiosInstance } from 'axios'
import { clearToken, getToken } from '@/services/Environment'
import router from '@/router'
import { RuntimeConfig } from '@/plugins/runtime-config/runtime-config'
import _Vue from 'vue'

declare module 'vue/types/vue' {
    interface Vue {
        $http: AxiosInstance;
    }
}

export function HttpPlugin(Vue: typeof _Vue, runtimeConfig: RuntimeConfig): void {
  const instance = axios.create({
    baseURL: `${runtimeConfig.API_ENDPOINT}/api`
  })

  instance.interceptors.request.use(
    (config) => {
      const token = getToken()
      config.headers.Authorization = `Bearer ${token}`
      config.headers['Content-Type'] = 'application/json'
      return config
    })

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response !== undefined && error.response.status === 401) {
        // Log out user if error 401 occurs
        clearToken()
        router.push({ path: '/login', query: { redirectTo: router.currentRoute.path } })
      } else {
        return Promise.reject(error)
      }
    })

  Vue.prototype.$http = instance
}


import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Account } from '@/models/account'
import NxaAlert from '@/components/NxaAlert.vue'

@Component({
  components: {
    NxaAlert
  }
})
export default class AccountPasswordResetDialog extends Vue {
  @Prop()
  private account: Account

  @PropSync('showDialog')
  private showDialogSync

  private isLoading = false

  private async send() {
    this.isLoading = true
    await this.$backend.get(`/accounts/organization/${this.account.organization.slug}/${this.account.slug}/send-password-reset-mail`)
    this.showDialogSync = false
    this.isLoading = false
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DeleteConfirmationDialog extends Vue {
  private showDialog = false
  private item: object = null

  @Prop()
  private onDelete: (item: object) => void

  public delete(item: object) {
    this.item = item
    this.showDialog = true
  }

  private confirmDelete() {
    this.showDialog = false
    this.onDelete(this.item)
  }
}



import { Component, Ref, Vue } from 'vue-property-decorator'
import VncViewer from '@/views/Servers/ServerDetailComponents/VncViewer.vue'
import { Tunnel } from '@/models/tunnel'
import { Server } from '@/models/server'

@Component({
  components: {
    VncViewer
  }
})
export default class ServerVnc extends Vue {
  @Ref('vnc-viewer') private vncViewer: VncViewer

  private server: Server
  private tunnel: Tunnel = null
  private heartBeatRef = -1

  public async mounted() {
    try {
      this.server = await this.$backend.get(`/servers/find/${this.$route.params.orgSlug}/${this.$route.params.serverSlug}`, Server)

      document.title += ' - ' + this.server.name

      await this.initTunnel()

      await this.vncViewer.connect(this.tunnel.forwardedServers.find((server) => server.type === 'vnc').url)
    } catch (error) {
      Vue.prototype.messages.$emit('error', this.$t('notification.remote_failed'))
    }
  }

  private async initTunnel() {
    let isLegacy: boolean
    if (Number(this.server.connectorVersion.split('.')[0]) < 2000) {
      isLegacy = false
    } else {
      isLegacy = true
    }
    this.tunnel = await this.$backend.get(`/servers/request-tunnel/${this.server.id}?isLegacy=${isLegacy}`, Tunnel)
    this.initHeartbeat()
  }

  private initHeartbeat() {
    const heartbeatUrl = this.tunnel.heartbeatUrl

    this.heartBeatRef = window.setInterval(async() => {
      try {
        await this.$http.get(heartbeatUrl)
      } catch (error) {
        this.tunnel = null
        window.clearInterval(this.heartBeatRef)
        Vue.prototype.messages.$emit('error', this.$t('notification.remote_connection_lost'))
      }
    }, 60 * 1000)
  }

  private closed() {
    console.log('closed')
    window.close()
  }
}


import { BackupMessage } from './backup-message'

export class BackupProgressMessage extends BackupMessage {
  public bytesTransferred: number

  constructor() {
    super()
    this.bytesTransferred = 0
    this.fileSize = 0
  }

  get progress(): number {
    if (this.bytesTransferred > 0 && this.fileSize > 0) {
      return 100 * this.bytesTransferred / this.fileSize
    }
    return 0
  }

  public transferredMb() {
    return this.bytesToMb(this.bytesTransferred)
  }
}


import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { Account } from '@/models/account'

@Component
export default class UserInactivityTimeoutDialog extends Vue {
  @PropSync('showDialog') private showDialogSync: boolean
  @Prop() private account: Account

  private isLoading = false
  private timeout: number
  private enabled = false

  private async change() {
    if (this.timeout >= 5 || !this.enabled) {
      this.isLoading = true
      if (!this.enabled) {
        this.timeout = 0
      }
      const result = await this.$backend.post(`/accounts/organization/${this.account.organization.slug}/${this.account.slug}/set-user-inactivity-timeout`, this.timeout * 60)
      if (result !== null) {
        this.account.inactivityTimeout = this.timeout * 60
      }
      this.isLoading = false
      this.showDialogSync = false
    } else {
      Vue.prototype.messages.$emit('error', this.$t('account.min_5_minuten'))
    }
  }

  @Watch('showDialog')
  private onShowDialogChanged() {
    if (this.showDialogSync) {
      this.enabled = this.account.inactivityTimeout > 0
      if (this.enabled) {
        this.timeout = this.account.inactivityTimeout / 60
      } else {
        this.timeout = 30
      }
    }
  }
}

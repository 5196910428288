
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import VncViewer from '@/views/Servers/ServerDetailComponents/VncViewer.vue'
import RemoteWeb from '@/views/Servers/ServerDetailComponents/RemoteWeb.vue'
import { Tunnel } from '@/models/tunnel'
import { Server } from '@/models/server'
import { isConsumer } from '@/services/Environment'

@Component({
  components: { RemoteWeb, VncViewer }
})
export default class Remote extends Vue {
  @Prop() private server: Server
  @Ref('remote-web-visu') private remoteWebVisu: RemoteWeb
  @Ref('remote-web-manager') private remoteWebManager: RemoteWeb
  @Ref('vnc-viewer') private vncViewer: VncViewer
  private tunnel: Tunnel = null
  private heartBeatRef = -1
  private webmanagerActive = false
  private webvisuActive = false
  private vncActive = false

  public async mounted() {
    try {
      this.getAvailableRemoteServices()
    } catch (ex) {
      Vue.prototype.messages.$emit('error', this.$t('notification.remote_failed'))
    }
  }

  public beforeDestroy() {
    if (this.tunnel) {
      try {
        this.$backend.get(`/servers/close-connection/${this.server.id}`)
      } catch (e) {
        // ignore
      }
    }
    window.clearInterval(this.heartBeatRef)
  }

  public isConsumerAccount() {
    return isConsumer()
  }

  private initHeartbeat() {
    const heartbeatUrl = this.tunnel.heartbeatUrl

    this.heartBeatRef = window.setInterval(async() => {
      try {
        await this.$http.get(heartbeatUrl)
      } catch (error) {
        this.tunnel = null
        window.clearInterval(this.heartBeatRef)
        Vue.prototype.messages.$emit('error', this.$t('notification.remote_connection_lost'))
      }
    }, 60 * 1000)
  }

  private async initTunnel() {
    if (this.tunnel !== null) {
      return
    }
    let isLegacy: boolean
    if (Number(this.server.connectorVersion.split('.')[0]) < 2000) {
      isLegacy = false
    } else {
      isLegacy = true
    }
    this.tunnel = await this.$backend.get(`/servers/request-tunnel/${this.server.id}?isLegacy=${isLegacy}`, Tunnel)
    this.initHeartbeat()
  }

  private showRemoteVncNewWindow() {
    const routeData = this.$router.resolve({ name: 'server.vnc' })
    window.open(routeData.href, '_blank')
  }

  private async showWebManager() {
    try {
      await this.initTunnel()
      this.webmanagerActive = true
      this.remoteWebManager.setUrl(this.tunnel.forwardedServers.find((server) => server.type === 'webmanager').url)
    } catch (error) {
      this.webmanagerActive = false
      Vue.prototype.messages.$emit('error', this.$t('notification.remote_failed'))
    }
  }

  private async showVisualization() {
    try {
      await this.initTunnel()
      this.webvisuActive = true
      this.remoteWebVisu.setUrl(this.tunnel.forwardedServers.find((server) => server.type === 'visualization').url)
    } catch (error) {
      this.webvisuActive = false
      Vue.prototype.messages.$emit('error', this.$t('notification.remote_failed'))
    }
  }

  private webmanagerClosed() {
    this.webmanagerActive = false
  }

  private webvisuClosed() {
    this.webvisuActive = false
  }

  private vncClosed() {
    this.vncActive = false
  }

  private async getAvailableRemoteServices() {
    this.server.availableRemoteServices = await this.$backend.get(`/servers/available-remote-services/${this.server.id}`)
  }

  private webManagerAvailable(): boolean {
    return this.server.availableRemoteServices.indexOf('WebManager') > -1
  }

  private webVisuAvailable(): boolean {
    return this.server.availableRemoteServices.indexOf('WebVisu') > -1
  }

  private vncAvailable(): boolean {
    return this.server.availableRemoteServices.indexOf('Vnc') > -1
  }
}

import moment, { Moment } from 'moment'
import { Transform } from 'class-transformer'

export class Organization {
    public id: number
    public name: string
    public serverLicenseCount: number
    public serverCount: number
    public connectedServerCount: number
    public enabled = true
    public slug: string
    public allowedStorageTraffic: number
    public usedStorage: number
    public allowedStorageUsage: number
    public usedStorageTraffic: number

    @Transform((value) => moment.tz(value, 'UTC'))
    public createdAt: Moment

    @Transform((value) => moment.tz(value, 'UTC'))
    public validUntil: Moment

    public allowedStorageTrafficMB() {
      return this._toMB(this.allowedStorageTraffic)
    }

    public usedStorageTrafficMB() {
      return this._toMB(this.usedStorageTraffic)
    }

    public usedStorageMB() {
      return this._toMB(this.usedStorage)
    }

    public allowedStorageUsageMB() {
      return this._toMB(this.allowedStorageUsage)
    }

    public storagePercentage() {
      return 100 * this.usedStorage / this.allowedStorageUsage
    }

    public trafficPercentage() {
      return 100 * this.usedStorageTraffic / this.allowedStorageTraffic
    }

    private _toMB(bytes: number) {
      return Math.round(100 * bytes / 1024.0 / 1024.0) / 100.0
    }
}

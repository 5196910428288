
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Server } from '@/models/server'
import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapSymbolLayer,
  atlas
} from 'vue-azure-maps'
import { ServerStatus } from '@/models/server-status'
import ItemStatusLabel from '@/components/ItemStatusLabel.vue'

@Component({
  components: {
    ItemStatusLabel,
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer
  }
})
export default class InfoCard extends Vue {
  @Prop()
  private server: Server

  @Prop()
  private latestConnectorVersion: string

  private updateUptimeInterval: NodeJS.Timeout

  private onlineIcons = {
    iconOptions: {
      ignorePlacement: true,
      allowOverlap: true,
      image: 'server-offline'
    }
  }

  public async created() {
    this.updateUptimeInterval = setInterval(() => this.server.updateUptimeString(), 1000)
  }

  public async beforeDestroy() {
    clearInterval(this.updateUptimeInterval)
  }

  public data() {
    return {
      ServerStatus
    }
  }

  @Watch('server', { deep: true })
  public onServerChanged() {
    switch (this.server.status) {
      case ServerStatus.Online:
        this.onlineIcons.iconOptions.image = 'server-online'
        break
      case ServerStatus.Offline:
        this.onlineIcons.iconOptions.image = 'server-offline'
        break
      case ServerStatus.Simulation:
        this.onlineIcons.iconOptions.image = 'server-simulation'
        break
      case ServerStatus.Standby:
        this.onlineIcons.iconOptions.image = 'server-standby'
        break
    }

    if (this.server.hasWarning) {
      this.onlineIcons.iconOptions.image = 'server-warning'
    }
  }

  public async mapReady(e: atlas.MapEvent) {
    await e.map.imageSprite.createFromTemplate('server-online', 'pin', '#7dba78')
    await e.map.imageSprite.createFromTemplate('server-offline', 'pin', '#cc5956')
    await e.map.imageSprite.createFromTemplate('server-warning', 'pin', '#f7ac69')
    await e.map.imageSprite.createFromTemplate('server-simulation', 'pin', '#9ca8b5')
    await e.map.imageSprite.createFromTemplate('server-standby', 'pin', '#4991ad')
  }
}

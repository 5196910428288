
import { Component, Ref, Vue } from 'vue-property-decorator'
import { Organization } from '@/models/organization.ts'
import { getUser, isAdmin, logOut } from '@/services/Environment'
import moment from 'moment'

@Component({})
export default class EditOrganization extends Vue {
    private organization!: Organization
    private showDialogSync = false
    @Ref('form') private readonly form!: HTMLFormElement
    private valid = false
    private editOrganization: Organization = null
    private isAdmin = false
    private showNameChangeInfo = false
    private storageTrafficDisplayValue = 0
    private storageUsageDisplayValue = 0
    private isLoading = false
    private timeLimited = false
    private validUntil: string
    private createdAt: string

    private async submitEditOrganization() {
      if (!this.form.validate()) {
        return
      }

      this.isLoading = true
      const nameChanged = this.editOrganization.name !== this.organization.name

      if (this.organization.id > 0 && nameChanged && !this.showNameChangeInfo) {
        this.isLoading = false
        this.showNameChangeInfo = true
        return
      }
      this.editOrganization.allowedStorageTraffic = this.storageTrafficDisplayValue * 1024 * 1024 * 1024
      this.editOrganization.allowedStorageUsage = this.storageUsageDisplayValue * 1024 * 1024 * 1024
      if (this.timeLimited) {
        this.editOrganization.validUntil = moment.utc(this.validUntil)
      } else {
        this.editOrganization.validUntil = null
      }
      await this.$backend.post('/admin/organizations', this.editOrganization)

      if (this.showNameChangeInfo && getUser().organization.id === this.editOrganization.id) {
        await logOut()
      }

      this.$emit('onChanged', this.editOrganization)
      this.showDialogSync = false
      this.isLoading = false
    }

    private cancel() {
      this.showDialogSync = false
    }

    private isOwnOrg() {
      return this.editOrganization.id === getUser().organization.id
    }

    private userCanDisableOrg() {
      return this.isAdmin && !this.isOwnOrg()
    }

    public open(organization: Organization) {
      this.organization = organization
      this.editOrganization = new Organization()
      Object.assign(this.editOrganization, this.organization)
      this.storageTrafficDisplayValue = this.organization.allowedStorageTraffic / 1024.0 / 1024.0 / 1024.0
      this.storageUsageDisplayValue = this.organization.allowedStorageUsage / 1024.0 / 1024.0 / 1024.0
      if (this.editOrganization.validUntil != null) {
        this.validUntil = this.editOrganization.validUntil.toISOString().split('T')[0]
        this.timeLimited = true
      } else {
        this.timeLimited = false
        this.validUntil = ''
      }
      if (this.editOrganization.createdAt != null) {
        this.createdAt = this.editOrganization.createdAt.toISOString().split('T')[0]
      }
      this.showNameChangeInfo = false
      this.showDialogSync = true
      this.isAdmin = isAdmin()
    }

    public new() {
      this.open(new Organization())
    }
}

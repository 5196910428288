import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item)?_c(VDialog,{attrs:{"max-width":"400","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showDialog=false}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('delete.title')))]),(_vm.item.hasOwnProperty('name'))?_c(VCardText,[_vm._v(_vm._s(_vm.$t('delete.text_specific', [_vm.item.name])))]):_c(VCardText,[_vm._v(_vm._s(_vm.$t('delete.text_generic')))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(_vm._s(_vm.$t("button.cancel")))]),_c(VBtn,{directives:[{name:"test",rawName:"v-test",value:('btn-confirm-delete'),expression:"'btn-confirm-delete'"}],attrs:{"color":"red","dark":""},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.$t('button.confirmDelete')))])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.account)?_c(VDialog,{attrs:{"height":"50vh","width":"50vw","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showDialogSync=false}},model:{value:(_vm.showDialogSync),callback:function ($$v) {_vm.showDialogSync=$$v},expression:"showDialogSync"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-key-variant")]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('password.change'))+": "+_vm._s(_vm.account.name))]),_c(VSpacer)],1),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"id":"passwordOld","name":"passwordOld","label":_vm.$t("label.password.old").toString(),"type":"password"},model:{value:(_vm.passwordOld),callback:function ($$v) {_vm.passwordOld=$$v},expression:"passwordOld"}}),_c(VTextField,{attrs:{"id":"password","name":"password","label":_vm.$t("label.password.new").toString(),"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{attrs:{"id":"passwordRepeat","name":"passwordRepeat","label":_vm.$t("label.password.repeat_new").toString(),"type":"password"},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialogSync=false}}},[_vm._v(_vm._s(_vm.$t('button.close')))]),_c(VBtn,{directives:[{name:"test",rawName:"v-test",value:('btn-change'),expression:"'btn-change'"}],attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.change}},[_vm._v(_vm._s(_vm.$t('button.change')))])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-column justify-center align-center"},[_c(VCardText,[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c(VProgressCircular,{staticClass:"ma-auto",attrs:{"size":75,"color":"teal","indeterminate":""}})],1):_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('label.remote.connection_open')))]),_c(VRow,[_c(VCol,[_c(VBtn,{staticStyle:{"minWidth":"150px"},attrs:{"outlined":"","color":"#00695C"},on:{"click":function($event){return _vm.remoteWindow.focus()}}},[_vm._v(_vm._s(_vm.$t('label.remote.focus_tab')))])],1),_c(VCol,[_c(VBtn,{staticStyle:{"minWidth":"150px"},attrs:{"outlined":"","color":"red"},on:{"click":function($event){return _vm.remoteWindow.close()}}},[_vm._v(_vm._s(_vm.$t('label.remote.close.label')))])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { TableHeader } from '@/models/table-header'
import { Component, Vue } from 'vue-property-decorator'
import EditUser from './EditUser.vue'
import DeleteUser from './DeleteUser.vue'
import AccountInvitationDialog from './AccountInvitationDialog.vue'
import AccountPasswordResetDialog from './AccountPasswordResetDialog.vue'
import { Account } from '@/models/account'
import NxaAlert from '@/components/NxaAlert.vue'
import { getUser, isAdmin } from '@/services/Environment'

@Component({
  components: {
    EditUser,
    DeleteUser,
    AccountInvitationDialog,
    AccountPasswordResetDialog,
    NxaAlert
  }
})
export default class Accounts extends Vue {
  private accounts: Account[] = []
  private account: Account = new Account()
  private isLoading = true
  private showEditUserDialog = false
  private showDeleteUserDialog = false
  private showInvitationDialog = false
  private showPwdResetDialog = false
  tableHeaders: TableHeader[]
  private search = ''
  private orgSlug = ''
  private user: Account = null
  private isAdmin = false

  private created() {
    this.initTableHeaders()
  }

  private async mounted() {
    await this.init()
  }

  private async init() {
    this.isLoading = true

    this.user = getUser()
    this.orgSlug = this.$route.params.orgSlug

    this.isAdmin = isAdmin()

    if (this.$route.name === 'allAccounts') {
      this.accounts = await this.$backend.list('/admin/accounts', Account)
    } else {
      this.accounts = await this.$backend.list(`/accounts/organization/${this.orgSlug}`, Account)
    }

    this.initTableHeaders()
    this.isLoading = false
  }

  private accountEditClicked(account: Account) {
    this.account = account
    this.showEditUserDialog = true
  }

  private accountDeleteClicked(account: Account) {
    this.account = account
    this.showDeleteUserDialog = true
  }

  private accountPwdResetClicked(account: Account) {
    this.account = account
    this.showPwdResetDialog = true
  }

  private async invite() {
    this.showInvitationDialog = true
  }

  private erase(accountId: number) {
    Vue.delete(
      this.accounts,
      this.accounts.indexOf(
        this.accounts.filter((account: Account) => account.id === accountId)[0]
      )
    )
  }

  private initTableHeaders() {
    this.tableHeaders = [
      { text: this.$t('table.accounts.name'), value: 'displayName' },
      { text: this.$t('table.accounts.email'), value: 'email' },
      { text: this.$t('table.accounts.role'), value: 'role.description' },
      { text: this.$t('table.accounts.servers'), value: 'servers', sortable: false }]
    if (this.isAdmin) {
      this.tableHeaders.push({ text: this.$t('table.accounts.organization').toString(), value: 'organization.name' })
    }
    this.tableHeaders.push({ text: this.$t('table.accounts.actions').toString(), value: 'action', sortable: false })
  }

  private contextMenuAvailable(account: Account): boolean {
    // Disable the edit button, if the selected user is a exe agent and the logged in user is not.
    return this.user.role.name === 'exe_agent' || account.role.name !== 'exe_agent'
  }
}

import { ServerGroup } from '@/models/server-group'
import { Type } from 'class-transformer'

export class Group {
    public id: number
    public name: string
    public organizationId: number
    public isAssignedToServer: boolean
    public notificationRecipients: string
    public timeZoneId: string
    @Type(() => ServerGroup)
    public serverGroups: ServerGroup[]
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { ServerStatus } from '@/models/server-status'
import { Server } from '@/models/server'

@Component
export default class NxaServerStatus extends Vue {
  @Prop()
  private server: Server

  public data() {
    return {
      ServerStatus
    }
  }
}


import BackupDialog from './BackupDialog.vue'
import { Server } from '@/models/server'
import { getErrorMsg } from '@/services/ErrorService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CloudMessagWrapper } from '@/models/cloud-message-wrapper'
import {
  UploadRejectedReason,
  UploadRejectedMessage
} from '@/models/createBackup/upload-rejected-ressage'
import { UploadApprovalBackupMessage } from '@/models/createBackup/upload-approval-backup-message'
import { BackupProgressMessage } from '@/models/createBackup/backup-progress-message'
import { RequestBackupMessage } from '@/models/createBackup/request-backup-bessage'
import { BackupSuccessMessage } from '@/models/createBackup/backup-success-message'
import { BackupFailedMessage } from '@/models/createBackup/backup-failed-message'
import { CancelBackupMessage } from '@/models/createBackup/cancel-backup-message'
import { plainToClass } from 'class-transformer'

@Component({
  components: {
    BackupDialog
  }
})
export default class BackupCard extends Vue {
  private backupInProgress = false
  private backupErrorMessage: string = null
  private currentWorkspaceName: string = null
  private resettingBackup = false
  private backupDialogVisible = false

  private isGenerating = false
  private isUploading = false
  private isFinished = false
  private isSuccessful = false
  private backupProgressMessage = new BackupProgressMessage()

  @Prop()
  private server: Server

  public async created() {
    this.$signalR.onBackupMessage(this.server, (wrapper) => {
      this.handleBackupMessage(wrapper)

      if (wrapper.messageType === 'BackupSuccessMessage') {
        const message = plainToClass(BackupSuccessMessage, wrapper.message)
        Vue.prototype.messages.$emit(
          'notification',
          this.$t('notification.backup_complete', [
            this.server.name,
            message.workspaceName
          ])
        )
      }
    })

    if (this.server.backupActive) {
      await this.loadCurrentBackupMessage()
    }
  }

  public async createBackup() {
    this.backupDialogVisible = true
  }

  public async startBackupCreation(
    logFiles: string,
    xLogicFiles: boolean,
    nxaVersionOutput: boolean
  ) {
    this.backupDialogVisible = false
    this.backupErrorMessage = null
    await this.$http.post(
      `/servers/${this.server.id}/request-backup?logFiles=${logFiles}&xlogic=${xLogicFiles}&nxaVersion=${nxaVersionOutput}`,
      `"${this.server.workspaceName}"`
    )
    this.server.backupActive = true
    this.isGenerating = true
    this.backupInProgress = true

    Vue.prototype.messages.$emit(
      'notification',
      this.$t('notification.backup_requested')
    )
  }

  public async resetBackup() {
    this.resettingBackup = true
    await this.$backend.get(`/servers/${this.server.id}/reset-current-backup`)
    this.server.backupActive = false
    await this.loadCurrentBackupMessage()
    this.resettingBackup = false
  }

  public async cancelBackup() {
    this.resettingBackup = true
    await this.$backend.get(`/servers/${this.server.id}/cancel-current-backup`)
    await this.loadCurrentBackupMessage()
    this.resettingBackup = false
  }

  private async loadCurrentBackupMessage() {
    const backupMessage = await this.$backend.get(
      `/servers/${this.server.id}/current-backup-message`,
      CloudMessagWrapper
    )

    this.handleBackupMessage(backupMessage)
  }

  private handleBackupMessage(wrapper: CloudMessagWrapper) {
    switch (wrapper.messageType) {
      case 'RequestBackupMessage':
        this.handleRequestBackupMessage(
          plainToClass(RequestBackupMessage, wrapper.message)
        )
        break

      case 'UploadApprovalBackupMessage':
        this.handleUploadApprovalBackupMessage(
          plainToClass(UploadApprovalBackupMessage, wrapper.message)
        )
        break

      case 'UploadRejectedMessage':
        this.handleUploadRejectedBackupMessage(
          plainToClass(UploadRejectedMessage, wrapper.message)
        )
        break

      case 'BackupProgressMessage':
        this.handleBackupProgressMessage(
          plainToClass(BackupProgressMessage, wrapper.message)
        )
        break

      case 'BackupSuccessMessage':
        this.handleBackupSuccessMessage(
          plainToClass(BackupSuccessMessage, wrapper.message)
        )
        break

      case 'BackupFailedMessage':
      case 'ServerOfflineMessage':
      case 'NoBackupRunningMessage':
        this.handleBackupFailedMessage(
          plainToClass(BackupFailedMessage, wrapper.message)
        )
        break

      case 'CancelBackupMessage':
        this.handleCancelBackupMessage(
          plainToClass(CancelBackupMessage, wrapper.message)
        )
        break

      default:
        this.backupProgressMessage = new BackupProgressMessage()
        this.currentWorkspaceName = null
        this.backupInProgress = false
        this.isGenerating = false
        this.isUploading = false
        this.isFinished = false
        this.isSuccessful = false
        this.backupErrorMessage = ''
        break
    }
  }

  private handleRequestBackupMessage(requestMessage: RequestBackupMessage) {
    this.backupProgressMessage = new BackupProgressMessage()
    this.currentWorkspaceName = requestMessage.workspaceName
    this.backupInProgress = true
    this.isGenerating = true
    this.isUploading = false
    this.isFinished = false
    this.backupErrorMessage = ''
    this.isSuccessful = false
  }

  private handleUploadRejectedBackupMessage(
    rejectedMessage: UploadRejectedMessage
  ) {
    this.backupInProgress = true
    switch (rejectedMessage.result) {
      case UploadRejectedReason.TrafficLimit:
        this.isGenerating = false
        this.isFinished = true
        this.backupErrorMessage = getErrorMsg('backup_error_traffic_limit')
        break

      case UploadRejectedReason.StorageLimit:
        this.isGenerating = false
        this.isFinished = true
        this.backupErrorMessage = getErrorMsg('backup_error_storage_limit')
        break

      default:
        break
    }
  }

  private handleUploadApprovalBackupMessage(
    approvalMessage: UploadApprovalBackupMessage
  ) {
    this.backupProgressMessage = new BackupProgressMessage()
    this.backupProgressMessage.fileSize = approvalMessage.fileSize
    this.backupProgressMessage.bytesTransferred = 0
    this.currentWorkspaceName = approvalMessage.workspaceName
    this.backupInProgress = true
    this.isFinished = false
    this.isGenerating = false
    this.isUploading = true
    this.backupErrorMessage = ''
    this.isSuccessful = false
  }

  private handleBackupProgressMessage(progressMessage: BackupProgressMessage) {
    this.backupProgressMessage = progressMessage
    this.currentWorkspaceName = progressMessage.workspaceName
    this.backupInProgress = true
    this.isGenerating = false
    this.isUploading = true
    this.isFinished = false
    this.backupErrorMessage = ''
    this.isSuccessful = false
  }

  private handleBackupSuccessMessage(successMessage: BackupSuccessMessage) {
    this.backupProgressMessage = new BackupProgressMessage()
    this.currentWorkspaceName = successMessage.workspaceName
    this.backupInProgress = true
    this.isGenerating = false
    this.isUploading = false
    this.isFinished = true
    this.isSuccessful = true
    this.backupErrorMessage = ''
  }

  private handleBackupFailedMessage(failedMessage: BackupFailedMessage) {
    this.backupProgressMessage = new BackupProgressMessage()
    this.currentWorkspaceName = failedMessage.workspaceName
    this.backupInProgress = true
    this.isGenerating = false
    this.isUploading = false
    this.isFinished = true
    this.isSuccessful = false
    this.backupErrorMessage = getErrorMsg('backup_error_failed')
  }

  private handleCancelBackupMessage(cancelMessage: CancelBackupMessage) {
    this.backupProgressMessage = new BackupProgressMessage()
    this.currentWorkspaceName = cancelMessage.workspaceName
    this.backupInProgress = true
    this.isGenerating = false
    this.isUploading = false
    this.isFinished = true
    this.isSuccessful = false
    this.backupErrorMessage = getErrorMsg('backup_error_canceled')
  }
}

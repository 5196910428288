import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import VueI18n from 'vue-i18n'
import messages from '../i18n/i18n'

Vue.use(Vuetify)
Vue.use(VueI18n)

const vuetifyI18n = {
  en: {
    $vuetify: {
      dataTable: {
        itemsPerPageText: 'Rows per page:',
        ariaLabel: {
          sortDescending: 'Sorted descending.',
          sortAscending: 'Sorted ascending.',
          sortNone: 'Not sorted.',
          activateNone: 'Activate to remove sorting.',
          activateDescending: 'Activate to sort descending.',
          activateAscending: 'Activate to sort ascending.'
        },
        sortBy: 'Sort by'
      },
      dataFooter: {
        itemsPerPageText: 'Items per page:',
        itemsPerPageAll: 'All',
        nextPage: 'Next page',
        prevPage: 'Previous page',
        firstPage: 'First page',
        lastPage: 'Last page',
        pageText: '{0}-{1} of {2}'
      },
      noDataText: 'No data available',
      dataIterator: {
        noResultsText: 'No matching records found',
        loadingText: 'Loading items...'
      }
    }
  },
  de: {
    $vuetify: {
      dataTable: {
        itemsPerPageText: 'Zeilen pro Seite:',
        ariaLabel: {
          sortDescending: 'Absteigend sortiert.',
          sortAscending: 'Aufsteigend sortiert.',
          sortNone: 'Nicht sortiert.',
          activateNone: 'Aktivieren um Sortierung aufzuheben.',
          activateDescending: 'Aktivieren um absteigend zu sortieren.',
          activateAscending: 'Aktivieren um aufsteigend zu sortieren.'
        },
        sortBy: 'Sortieren nach '
      },
      dataFooter: {
        itemsPerPageText: 'Einträge pro Seite:',
        itemsPerPageAll: 'Alle',
        nextPage: 'Nächste Seite',
        prevPage: 'Vorherige Seite',
        firstPage: 'Erste Seite',
        lastPage: 'Letzte Seite',
        pageText: '{0}-{1} von {2}'
      },
      noDataText: 'Keine Daten verfügbar',
      dataIterator: {
        noResultsText: 'Keine passenden Einträge gefunden.',
        loadingText: 'Daten werden geladen...'
      }
    }
  },
  es: {
    $vuetify: {
      dataTable: {
        itemsPerPageText: 'Filas por página:',
        ariaLabel: {
          sortDescending: 'Ordenados descendente.',
          sortAscending: 'Ordenado ascendente.',
          sortNone: 'No clasificado.',
          activateNone: 'Activar para eliminar la clasificación.',
          activateDescending: 'Activar para ordenar descendente.',
          activateAscending: 'Activar para ordenar ascendente.'
        },
        sortBy: 'Ordenar por'
      },
      dataFooter: {
        itemsPerPageText: 'Elementos por página:',
        itemsPerPageAll: 'Todos',
        nextPage: 'Página siguiente',
        prevPage: 'Página anterior',
        firstPage: 'Primera página',
        lastPage: 'Última página',
        pageText: '{0}-{1} de {2}'
      },
      noDataText: 'No hay datos disponibles',
      dataIterator: {
        noResultsText: 'No se encontraron registros coincidentes',
        loadingText: 'Cargando elementos...'
      }
    }
  }
}

let locale = navigator.language.split('-')[0] // en-US => en
if (!Object.keys(messages).find((lang) => locale === lang)) {
  locale = 'en'
}
const vue18n = new VueI18n({ locale, messages: vuetifyI18n })

export default new Vuetify({
  lang: {
    current: locale,
    t: (key, ...params) => vue18n.t(key, params) as string
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#378B81'
      }
    }
  }
})


import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Server } from '@/models/server'
import NxaServerStatus from '@/components/NxaServerStatus.vue'

@Component({
  components: {
    NxaServerStatus
  }
})
export default class NxaServerDialog extends Vue {
    @Prop()
    public servers: Server[]

    public panel = [0]

    private dialog = false

    @Watch('servers')
    public onPropertyChanged(value: Server[]) {
      this.dialog = value.length > 0
      this.panel = [0]
    }

    private closeDialog() {
      this.dialog = false
    }
}

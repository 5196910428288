import { CloudMessage } from '../cloud-message'

export class BackupMessage extends CloudMessage {
  public id: number
  public fileSize: number
  public fileName: string
  public workspaceName: string

  get fileSizeMb(): number {
    return this.bytesToMb(this.fileSize)
  }

  protected bytesToMb(bytes: number) {
    return Math.round(100 * bytes / 1024.0 / 1024.0) / 100.0
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { logOut } from '@/services/Environment'

@Component
export default class BackendStatusSnackbar extends Vue {
    private readonly graceTimeSeconds = 5
    private readonly defaultLogoutSeconds: number = 30
    private isConnectionWarningVisible = false
    private isConnectionReestablishedVisible = false
    private logoutSeconds: number = this.defaultLogoutSeconds

    public mounted() {
      setTimeout(this.watchSignalR, 5000)
    }

    private watchSignalR() {
      this.$signalR.onClosed(() => {
        setTimeout(() => {
          if (this.$signalR.isConnected()) {
            // disconnect duration was less than 'graceTimeSeconds', don't bug the user about it
            return
          }

          this.logoutSeconds = this.defaultLogoutSeconds
          this.isConnectionWarningVisible = true
          this.tickLogoutTimer()
        },
        this.graceTimeSeconds * 1000)
      })
    }

    private tickLogoutTimer() {
      if (this.$signalR.isConnected()) {
        this.isConnectionWarningVisible = false
        this.isConnectionReestablishedVisible = true
      } else if (this.logoutSeconds > 0) {
        // wait
        this.logoutSeconds--
        setTimeout(this.tickLogoutTimer, 1000)
      } else {
        logOut()
      }
    }
}



import { RemoteAccess } from '@/models/remote-access'
import { TableHeader } from '@/models/table-header'
import { Component, Prop, PropSync, Vue, Ref, Watch } from 'vue-property-decorator'

@Component
export default class EditRemoteAccess extends Vue {
    private isLoading = false
    private valid = false
    private devices: {device: string}[] = []
    private editDevice = ''
    private type = ''

    private types: { value: string; label: string} [] = [
      { value: 'visu', label: 'Visualization' },
      { value: 'webmanager', label: 'Web Manager' },
      { value: 'api', label: 'Cloud Api' }
    ]

    headers: TableHeader[] = [
      { text: '', value: 'device' }
    ]

    @Prop()
    private remoteAccess: RemoteAccess

    @PropSync('showDialog')
    private showDialogSync

    @Ref('form') private readonly form!: HTMLFormElement

    private editRemoteAccess: RemoteAccess = null

    private async created() {
      this.isLoading = true
    }

    private async submitEditRemoteAccess() {
      if (!this.form.validate()) {
        return
      }

      this.isLoading = true
      this.editRemoteAccess.type = this.type
      if (this.editRemoteAccess.type !== 'visu') {
        this.editRemoteAccess.allowedDevices = ''
      } else {
        this.editRemoteAccess.allowedDevices = this.devices.map(d => d.device).join(';')
      }

      if (this.editRemoteAccess.id) {
        await this.$http.put('/remoteaccess', this.editRemoteAccess)
      } else {
        this.editRemoteAccess.cloudProjectId = +this.$route.params.projectId
        await this.$http.post('/remoteaccess', this.editRemoteAccess)
      }
      Object.assign(this.remoteAccess, this.editRemoteAccess)

      this.showDialogSync = false
      this.isLoading = false
      this.$emit('onChanged')
    }

    @Watch('showDialog')
    private async onShowDialogChanged() {
      if (this.showDialogSync) {
        this.editRemoteAccess = null
        this.isLoading = true

        this.editRemoteAccess = new RemoteAccess()
        Object.assign(this.editRemoteAccess, this.remoteAccess)
        this.type = this.editRemoteAccess.type
        if (this.editRemoteAccess.allowedDevices != null) {
          this.devices = this.editRemoteAccess.allowedDevices.split(';').map(device => ({ device }))
        } else {
          this.devices = []
        }
        this.isLoading = false
      }
    }

    private removeDevice(device: string) {
      this.devices = this.devices.filter(d => d.device !== device)
    }

    private addDevice() {
      this.devices.push({ device: '' })
    }

    private validateDeviceName(name: string): string {
      const regex = new RegExp('[^a-zA-Z0-9_-]+')
      if (regex.test(name)) {
        return this.$t('remote-access.invalid-device-name').toString()
      }
      return null
    }
}

export class ServerItem {
    static databaseKey = 'NETx\\Server\\Database\\Connected'

    public item: string
    public description: string
    public value: string
    public available: boolean
    public group: string
    public lastChange: Date
    public warning: boolean

    public init() {
      this.setGroup(this.item)
      this.warning = this.hasWarningValue()
      return this
    }

    public update(item: ServerItem) {
      this.value = item.value
      this.description = item.description
      this.available = item.available

      this.warning = this.hasWarningValue()
      this.lastChange = new Date(item.lastChange)
    }

    public hasValue(value: string) {
      return this.available && this.value === value
    }

    private hasWarningValue() {
      // tslint:disable:max-line-length
      return this.hasKeyAndMatches(ServerItem.databaseKey, (value) => value !== 'True') || // Server is online but DB is not connected
            this.hasKeyAndMatches('NETx\\Server\\WebManager\\Status', (value) => value === '1') || // Server is online but WebManager is not connected
            this.hasKeyAndMatches('NETx\\Server\\WebServer\\Status', (value) => value === '1') || // Server is online but WebManager is not connected
            this.hasKeyAndMatches('NETx\\Module\\Reporting\\ReportingServerStatus', (value) => value === 'False') // Server is online but Reporting Server is not connected
    }

    private hasKeyAndMatches(key: string, match: (value) => boolean) {
      return this.item === key && this.available && match(this.value)
    }

    private setGroup(key: string): void {
      const path = key.split('\\')
      if (path.length > 1) {
        this.group = path[path.length - 2]
      } else {
        this.group = key
      }
    }
}

import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Join from './views/Join.vue'
import ServerDetail from './views/Servers/ServerDetail.vue'
import ServerList from './views/Servers/ServerList.vue'
import GroupList from './views/Servers/GroupList.vue'
import ServerMap from './views/Servers/ServerMap.vue'
import Accounts from './views/management/Accounts.vue'
import CloudProjectList from './views/Servers/CloudProjectList.vue'
import RemoteAccessList from './views/Servers/RemoteAccesList.vue'
import Organization from './views/management/OrganizationOverview.vue'
import Organizations from './views/management/Organizations.vue'
import { getToken } from './services/Environment'
import ServerVnc from '@/views/Servers/ServerVnc.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: { name: 'servers.list' },
      meta: {
        requiresAuthentication: true
      },
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          redirect: { name: 'servers.list' }
        },
        {
          path: '/:orgSlug/servers',
          name: 'servers.list',
          component: ServerList
        },
        {
          path: '/:orgSlug/groups',
          name: 'groups.list',
          component: GroupList
        },
        {
          path: '/:orgSlug/map',
          name: 'servers.map',
          component: ServerMap
        },
        {
          path: '/:orgSlug/cloud-projects',
          name: 'cloud.projects',
          component: CloudProjectList
        },
        {
          path: ':orgSlug/cloud-projects/:projectId/remote-access-list',
          name: 'cloud.remote-acces',
          component: RemoteAccessList
        },
        {
          path: '/:orgSlug/servers/:serverSlug',
          name: 'server.detail',
          component: ServerDetail
        },

        {
          path: '/:orgSlug/info',
          name: 'organization',
          component: Organization
        },
        {
          path: '/admin/organizations',
          name: 'organizations',
          component: Organizations
        },
        {
          path: '/admin/accounts',
          name: 'allAccounts',
          component: Accounts
        },
        {
          path: '/:orgSlug/accounts',
          name: 'accounts',
          component: Accounts
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
      meta: {
        requiresAnonymous: true
      }
    },
    {
      path: '/join/:token',
      name: 'join',
      component: Join
    },
    {
      path: '/password-reset/:token',
      name: 'passwordReset',
      component: Join,
      props: { isPwdReset: true }
    },
    {
      path: '/:orgSlug/servers/:serverSlug/vnc',
      name: 'server.vnc',
      component: ServerVnc
    },
    {
      path: '*',
      redirect: { name: 'dashboard' }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const token = getToken()

  if (to.matched.some((m) => m.meta.requiresAuthentication)) {
    if (!token) {
      next({ path: '/login', query: { redirect: to.path } })
    } else {
      next()
    }
  } else if (to.matched.some((m) => m.meta.requiresAnonymous)) {
    if (token) {
      next('servers.list')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class NxaAlert extends Vue {
  @Prop()
  private type: string

  @Prop()
  private text: string

  @Prop()
  private visible: boolean
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { ServerType } from '@/models/server-type'
import { Server } from '@/models/server'

@Component
export default class ServerTypeView extends Vue {
  @Prop()
  private server: Server

  public data() {
    return {
      ServerType
    }
  }
}

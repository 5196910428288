
import NxaAlert from '@/components/NxaAlert.vue'
import { Account } from '@/models/account'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    NxaAlert
  }
})
export default class DeleteUser extends Vue {
  @Prop()
  private account: Account

  @PropSync('showDialog')
  private showDialogSync

  private isDeleting = false

  private async eraseAccount() {
    this.isDeleting = true
    await this.$backend.delete(`/accounts/organization/${this.account.organization.slug}/${this.account.slug}`)
    this.$emit('erase', this.account.id)
    this.showDialogSync = false
    this.isDeleting = false
  }
}

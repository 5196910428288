import moment, { Moment } from 'moment'
import { Transform } from 'class-transformer'
import { LogFileOptions } from './log-file-options'

export class BlobFile {
    public name: string
    public workspace: string
    public path: string

    @Transform((value) => moment.tz(value, 'UTC'))
    public created: Moment

    @Transform((value) => moment.tz(value, 'UTC'))
    public uploaded: Moment

    public dataType: string
    public isDownloadingFile = false
    public size: number
    public progress = 0
    public logFileOption: LogFileOptions
    public includeNxaVersionOutput: boolean
    public includeXLogicFiles: boolean

    get sizeMb(): number {
      return Math.round(100 * this.size / 1024.0 / 1024.0) / 100.0
    }
}


import { Component, Vue } from 'vue-property-decorator'
import { getUser, logOut, isConsumer } from '@/services/Environment'
import ChangePasswordDialog from '@/views/ChangePasswordDialog.vue'
import UserInactivityTimeoutDialog from '@/views/UserInactivityTimeoutDialog.vue'
import { Account } from '@/models/account'
import TfaDialog from '@/views/TfaDialog.vue'
import moment from 'moment'

@Component({
  components: { TfaDialog: TfaDialog, ChangePasswordDialog, UserInactivityTimeoutDialog }
})
export default class Navigation extends Vue {
  public drawer: boolean | null = null
  public user: Account = null

  private showManagement = false
  private showOrgManagement = false
  private showChangePasswordDialog = false
  private showTfaDialog = false
  private showUserInactivityTimeoutDialog = false
  private showOrganizationTimeLimitWarning = false
  private showOrganizationExpiredWarning = false
  private organizationValidUntilString = ''

  public async created() {
    this.user = getUser()

    if (this.user) {
      this.showManagement = this.user.role.name === 'exe_agent'
      this.showOrgManagement = this.showManagement || this.user.role.name === 'org_holder'
      if (this.user.organization.validUntil != null) {
        const duration = moment.duration(this.user.organization.validUntil.diff(moment.now())).asMonths()
        this.organizationValidUntilString = this.user.organization.validUntil.locale(navigator.language).format('L')
        this.showOrganizationTimeLimitWarning = duration < 1 && duration > 0
        this.showOrganizationExpiredWarning = duration <= 0
      }
    }
  }

  public logout() {
    logOut()
  }

  private changePassword() {
    this.showChangePasswordDialog = true
  }

  private isConsumerAccount(): boolean {
    return isConsumer()
  }
}

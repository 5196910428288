
import { Component, Vue } from 'vue-property-decorator'
import { Organization } from '@/models/organization'

@Component({})
export default class DeleteOrganizationDialog extends Vue {
  private organization: Organization = new Organization()

  private showDialogSync = false

  private step = 1

  private accountCount = 0
  private serverCount = 0
  private isDeleting = false

  private async nextStep() {
    if (this.step === 1) {
      if (this.accountCount > 0 || this.serverCount > 0) {
        this.step = 2
      } else {
        await this._remove()
      }
    } else if (this.step === 2) {
      await this._remove()
    }
  }

  public async delete(organization: Organization) {
    this.organization = organization
    this.step = 1
    this.accountCount = await this.$backend.get(`/accounts/organization/${this.organization.slug}/count`) as number
    this.serverCount = await this.$backend.get(`/servers/organization/${this.organization.slug}/count`) as number
    this.showDialogSync = true
  }

  private async _remove() {
    this.isDeleting = true
    await this.$backend.delete(`/organizations/slug/${this.organization.slug}`)

    this.showDialogSync = false
    this.isDeleting = false

    this.$emit('onDeleted', this.organization.id)
  }
}

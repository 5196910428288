

import { CloudProject } from '@/models/cloud-project'
import { Server } from '@/models/server'
import { getOrgSlug } from '@/services/Environment'
import { Component, Prop, PropSync, Vue, Ref, Watch } from 'vue-property-decorator'

@Component
export default class EditCloudProject extends Vue {
    private isLoading = false
    private valid = false
    private backupWsRequired = false
    private apiUrl = ''
    private visuUrl = ''

    @Prop()
    private cloudProject: CloudProject

    @Prop()
    private projectList: CloudProject[]

    @PropSync('showDialog')
    private showDialogSync

    @Ref('form') private readonly form!: HTMLFormElement

    private editProject: CloudProject = null
    private servers: Server[]

    private async created() {
      this.isLoading = true
    }

    private async submitEditProject() {
      if (!this.form.validate()) {
        return
      }

      this.isLoading = true
      if (this.projectList.some(cp => cp.mainServerId === this.editProject.mainServerId &&
          cp.mainServerWorkspace === this.editProject.mainServerWorkspace && cp.id !== this.editProject.id)) {
        Vue.prototype.messages.$emit('error', this.$t('cloudproject.mainexists'))
        this.isLoading = false
        return
      }

      if (this.editProject.backupServerId !== undefined && this.editProject.backupServerWorkspace !== undefined &&
        this.projectList.some(cp => cp.backupServerId === this.editProject.backupServerId &&
          cp.backupServerWorkspace === this.editProject.backupServerWorkspace && cp.id !== this.editProject.id)) {
        Vue.prototype.messages.$emit('error', this.$t('cloudproject.backupexists'))
        this.isLoading = false
        return
      }

      if (this.projectList.some(cp => cp.projectName === this.editProject.projectName && cp.id !== this.editProject.id)) {
        Vue.prototype.messages.$emit('error', this.$t('cloudproject.projectexists'))
        this.isLoading = false
        return
      }

      if (this.editProject.projectId) {
        await this.$http.put('/cloudprojects', this.editProject)
      } else {
        await this.$http.post('/cloudprojects', this.editProject)
      }
      Object.assign(this.cloudProject, this.editProject)

      this.showDialogSync = false
      this.isLoading = false
      this.$emit('onChanged')
    }

    onChange(changeMainServer: boolean) {
      if (changeMainServer) {
        this.editProject.setMainServer(this.editProject.mainServer)
        if (this.editProject.backupServerId === this.editProject.mainServerId) {
          this.editProject.setBackupServer(null)
        }
      } else {
        this.editProject.setBackupServer(this.editProject.backupServer)
        if (this.editProject.mainServerId === this.editProject.backupServerId) {
          this.editProject.setMainServer(null)
        }
      }
      this.backupWsRequired = this.editProject.backupServer != null
    }

    @Watch('showDialog')
    private async onShowDialogChanged() {
      if (this.showDialogSync) {
        this.editProject = null
        this.isLoading = true

        const orgSlug = getOrgSlug()

        this.servers = await this.$backend.list(
            `/servers/organization/${orgSlug}`, Server)

        this.servers.forEach(server => {
          server.setFieldsFromServerItems()
        })
        this.editProject = new CloudProject()
        Object.assign(this.editProject, this.cloudProject)
        this.backupWsRequired = this.editProject.backupServer != null
        this.isLoading = false
      }
    }
}


import { i18n } from '@/main'
import { logOut } from '@/services/Environment'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class MessageSnackbar extends Vue {
  private isSnackbarVisible = false
  private snackbarTitle: string = null
  private snackbarText: string = null
  private color = 'primary'
  private right = true
  private timeout = 10000
  private reason: string = null
  private showLogout = false

  public async created() {
    Vue.prototype.messages.$on('notification', this.showNotification)
    Vue.prototype.messages.$on('error', this.showError)
    Vue.prototype.messages.$on('fatal', this.showFatal)
  }

  private showNotification(text: string) {
    this.reset()
    this.$nextTick(() => {
      this.snackbarText = text
      this.color = 'primary'
      this.right = true
      this.timeout = 10000
      this.showLogout = false
      this.isSnackbarVisible = true
    })
  }

  private showError(text: string) {
    this.reset()
    this.$nextTick(() => {
      this.snackbarTitle = text
      this.color = '#cc5956'
      this.right = false
      this.timeout = 10000
      this.showLogout = false
      this.isSnackbarVisible = true
    })
  }

  private showFatal(reason: string) {
    this.reset()
    this.$nextTick(() => {
      this.snackbarTitle = i18n.t('error.internal') as string
      this.reason = reason
      this.snackbarText = i18n.t('error.internal_solution') as string
      this.color = '#cc5956'
      this.right = false
      this.timeout = -1
      this.showLogout = true
      this.isSnackbarVisible = true
    })
  }

  private static logout() {
    logOut()
  }

  private reset() {
    this.isSnackbarVisible = false
    this.snackbarTitle = ''
    this.snackbarText = ''
    this.reason = null
  }
}

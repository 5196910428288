
import { Server } from '@/models/server'
import { ServerStatus } from '@/models/server-status'
import GroupList from '@/views/Servers/GroupList.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import EditServer from './EditServer.vue'
import EditBackupSchedule from './EditBackupSchedule.vue'
import ActivitiesTable from './ServerDetailComponents/ActivitiesTable.vue'
import BackupsTable from './ServerDetailComponents/BackupsTable.vue'
import ItemsTable from './ServerDetailComponents/ItemsTable.vue'
import ServerDetailMap from './ServerDetailComponents/ServerDetailMap.vue'
import ServerLogsTable from './ServerDetailComponents/ServerLogsTable.vue'
import NxaServerStatus from '@/components/NxaServerStatus.vue'
import ServerTypeView from '@/components/ServerTypeView.vue'
import { isAdmin, isConsumer, isOrgMember } from '@/services/Environment'
import BackupCard from '@/views/Servers/ServerDetailComponents/BackupCard.vue'
import BackupRestoreCard from '@/views/Servers/ServerDetailComponents/BackupRestoreCard.vue'
import InfoCard from '@/views/Servers/ServerDetailComponents/InfoCard.vue'
import Remote from '@/views/Servers/ServerDetailComponents/Remote.vue'
import { BlobFile } from '@/models/blob-file'

@Component({
  components: {
    Remote,
    InfoCard,
    BackupCard,
    BackupRestoreCard,
    ItemsTable,
    BackupsTable,
    ServerLogsTable,
    NxaServerStatus,
    EditServer,
    EditBackupSchedule,
    ServerDetailMap,
    ActivitiesTable,
    ServerTypeView,
    GroupList
  }

})
export default class ServerDetail extends Vue {
    private showDeleteDialog = false
    private showConfirmDeleteBackupsDialog = false
    private showGroupDialog = false
    private backupsCount = 0
    private isServerDeleting = false
    private latestConnectorVersion: string

    private isLoading = true
    private isSuperAdmin = false
    private orgSlug = ''

    private server: Server = null
    private oldServerStatus: ServerStatus = null

    private activeTab = 'info'

    @Watch('$route.params.serverSlug')
    public async onRouteChanged() {
      await this.loadServer()
    }

    @Watch('server.status')
    public async onServerStatusChanged() {
      if (this.oldServerStatus === ServerStatus.Offline && this.server.status !== this.oldServerStatus) {
        // Server started/ connected
        // Reload server bacause fields like location, ... cloud have been updated.
        await this.loadServer()
      }
      this.oldServerStatus = this.server.status
    }

    public data() {
      return {
        ServerStatus
      }
    }

    public async mounted() {
      this.isSuperAdmin = isAdmin()
      this.orgSlug = this.$route.params.orgSlug
      this.latestConnectorVersion = await this.$backend.get('/servers/latest-connector-version')
      await this.loadServer()
      this.isLoading = false
    }

    public beforeDestroy() {
      this.server.destroy()
    }

    public isConsumerAccount() {
      return isConsumer()
    }

    public canEditServer() {
      return !isConsumer()
    }

    private async loadServer() {
      this.server = await this.$backend.get(
            `/servers/find/${this.orgSlug}/${this.$route.params.serverSlug}`, Server)

      this.server.init(this.orgSlug, this.$signalR)
      this.server.onDeleted(() => this.$router.push(`/${this.orgSlug}/servers`))
    }

    private async deleteServer(confirmDeleteBackups: boolean) {
      if (!confirmDeleteBackups) {
        this.backupsCount = (await this.$backend.list(`/servers/${this.server.id}/list-backups`, BlobFile)).length
        if (this.backupsCount > 0) {
          this.showDeleteDialog = false
          this.showConfirmDeleteBackupsDialog = true
          return
        }
      }
      this.isServerDeleting = true
      this.server.isDeleted = true
      await this.$backend.delete(`/servers/organization/${this.orgSlug}/${this.server.slug}`)
      this.showDeleteDialog = false
      this.$router.push(`/${this.orgSlug}/servers`)
      this.isServerDeleting = false
    }
}

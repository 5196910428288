import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"height":"50vh","width":"50vw","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showDialogSync=false}},model:{value:(_vm.showDialogSync),callback:function ($$v) {_vm.showDialogSync=$$v},expression:"showDialogSync"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-lock-reset")]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("password.resetTitle"))+": "+_vm._s(_vm.account.name))]),_c(VSpacer)],1),_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm.$t("password.resetSentTo"))+" "+_vm._s(_vm.account.email))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialogSync=false}}},[_vm._v(_vm._s(_vm.$t("button.close")))]),_c(VBtn,{directives:[{name:"test",rawName:"v-test",value:('password-reset-submit'),expression:"'password-reset-submit'"}],attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.send()}}},[_vm._v(_vm._s(_vm.$t("button.send")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { BackupMessage } from './backup-message'

export class UploadRejectedMessage extends BackupMessage {
    public result: UploadRejectedReason
}

export enum UploadRejectedReason {
  TrafficLimit = 'trafficLimit',
  StorageLimit = 'storageLimit'
}

import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mda-1 elevation-2"},[_c(VToolbar,{staticClass:"elevation-2",attrs:{"flat":""}},[_c(VIcon,[_vm._v("mdi-map-marker-radius")]),_c(VToolbarTitle,{directives:[{name:"test",rawName:"v-test",value:('title'),expression:"'title'"}]},[_vm._v(_vm._s(_vm.$t("navigation.servers")))])],1),(_vm.isLoading)?_c('div',[_c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VProgressLinear,{attrs:{"color":"primary","size":50,"indeterminate":""}})],1)],1)],1):(_vm.servers.length > 0)?_c('div',[_c('AzureMap',{staticClass:"map",attrs:{"center":[_vm.servers[0].longitude, _vm.servers[0].latitude],"zoom":8,"map-style":_vm.$route.query.testmode ?'blank':'grayscale_light',"show-logo":false,"preserve-drawing-buffer":_vm.$route.query.testmode === 'true'},on:{"ready":_vm.mapReady}},[_c('AzureMapDataSource',[_vm._l((_vm.servers),function(server){return _c('AzureMapPoint',{key:server.slug,attrs:{"longitude":server.longitude,"latitude":server.latitude,"properties":{id: server.id, status: server.status, hasWarning: server.hasWarning}}})}),_c('AzureMapSymbolLayer',{attrs:{"options":_vm.onlineIcons},on:{"click":_vm.markerClicked}}),_c('AzureMapSymbolLayer',{attrs:{"options":_vm.simulationIcons},on:{"click":_vm.markerClicked}}),_c('AzureMapSymbolLayer',{attrs:{"options":_vm.standbyIcons},on:{"click":_vm.markerClicked}}),_c('AzureMapSymbolLayer',{attrs:{"options":_vm.warningIcons},on:{"click":_vm.markerClicked}}),_c('AzureMapSymbolLayer',{attrs:{"options":_vm.offlineIcons},on:{"click":_vm.markerClicked}})],2)],1),_c('nxa-server-dialog',{attrs:{"servers":_vm.selectedServers}})],1):_c(VContainer,{attrs:{"fill-height":"","justify-center":""}},[_vm._v(_vm._s(_vm.$t('servermap.noclients')))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { RuntimeConfig } from '@/plugins/runtime-config/runtime-config'

export async function RuntimeConfigPlugin(): Promise<RuntimeConfig> {
  const configPromise = await fetch('/config/runtimeConfig.json')
  const runtimeConfig = await configPromise.json() as RuntimeConfig
  if (runtimeConfig.production === undefined) {
    runtimeConfig.production = true
  }

  const versionPromise = await fetch('/version.txt')
  runtimeConfig.VERSION = await versionPromise.text()
  return runtimeConfig
}


import { Server } from '@/models/server'
import { Component, Ref, Vue } from 'vue-property-decorator'
import { ServerFamily } from '@/models/server-family'
import { getOrgSlug, getUser } from '@/services/Environment'

@Component({})
export default class ServerRegistration extends Vue {
  private showDialog = false
  @Ref('form') private readonly form!: HTMLFormElement
  private registered = false
  private isLoading = false
  private name: string = null
  private showServerRegistration = false
  private serverFamily: ServerFamily

  public async created() {
    setTimeout(this.probeLocalServer, 1000)
  }

  private async probeLocalServer() {
    try {
      const isOwnOrganization = getUser().organization.slug === getOrgSlug()
      this.serverFamily = await this.$serverRegistration.getAvailableServerFamily()
      this.showServerRegistration = this.serverFamily !== undefined && isOwnOrganization
      if (this.showServerRegistration) {
        this.$emit('local-server-found')
      }
    } catch (error) {
      this.showServerRegistration = false
    }
  }

  private async registerServer() {
    if (!this.form.validate()) {
      return
    }

    this.isLoading = true
    const server = await this.$backend.post(
      '/servers',
      { name: this.name, serverType: this.serverFamily },
      Server
    )
    await this.$serverRegistration.register(server.apiKey)
    this.registered = true
    this.$root.$emit('reload_servers')
    setTimeout(() => {
      this.showDialog = false
      this.showServerRegistration = false
      this.name = null
    }, 1000)
  }

  private cancel() {
    this.form.resetValidation()
    this.name = ''
    this.isLoading = false
    this.showDialog = false
  }
}

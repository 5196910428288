import moment from 'moment'

export default function customDatatableSort(items, sortBy, sortDesc) {
  items.sort((a, b) => {
    const valA = a[sortBy[0]]
    const valB = b[sortBy[0]]

    if (valA === null || valB === null || typeof valA === 'undefined' || typeof valB === 'undefined') { return 0 }
    if (moment.isMoment(items[0][sortBy[0]]) || (typeof valA === 'number' && typeof valB === 'number')) {
      if (!sortDesc[0]) {
        return valA - valB
      } else {
        return valB - valA
      }
    } else if (typeof valA === 'string' && typeof valB === 'string') {
      if (!sortDesc[0]) {
        return valA.toLowerCase().localeCompare(valB.toLowerCase())
      } else {
        return valB.toLowerCase().localeCompare(valA.toLowerCase())
      }
    } else if (typeof valA === 'boolean' && typeof valB === 'boolean') {
      if (valA === valB) return 0

      if (!sortDesc[0]) {
        return valA ? -1 : 1
      } else {
        return valA ? 1 : -1
      }
    } else {
      return 0
    }
  })

  return items
}

export function customDatatableSortLambda(valA, valB) {
  if (valA === null || valB === null || typeof valA === 'undefined' || typeof valB === 'undefined') { return 0 }
  if (typeof valA === 'number' && typeof valB === 'number') {
    return valA - valB
  } else if (typeof valA === 'string' && typeof valB === 'string') {
    return valA.toLowerCase().localeCompare(valB.toLowerCase())
  } else {
    return 0
  }
}


import ActivityLabel from '@/components/ActivityLabel.vue'
import { TableHeader } from '@/models/table-header'
import { plainToClass } from 'class-transformer'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ServerActivity } from '@/models/server-activity'
import { Server } from '@/models/server'
import { DataOptions } from 'vuetify'

@Component({
  components: {
    ActivityLabel
  }
})
export default class ActivitiesTable extends Vue {
    private activities: ServerActivity[] = []

    @Prop()
    private server: Server

    private start = 0
    private amount = 50
    private limit = 100
    private busy = false
    private tableOptions: DataOptions = {
      sortBy: ['timestamp'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      itemsPerPage: -1,
      multiSort: false,
      mustSort: true,
      page: 1
    }

    headers: TableHeader[] = [
      { text: this.$t('table.activities.time'), value: 'timestamp' },
      { text: this.$t('table.activities.type'), value: 'type', sortable: false },
      { text: this.$t('table.activities.account'), value: 'account.displayName', sortable: false },
      { text: this.$t('table.activities.details'), value: 'message', sortable: false },
      { text: '', value: 'notificationSent', sortable: false }
    ]

    public created() {
      this.$signalR.onServerActivity(this.server, (serverActivity) => {
        this.activities.unshift(serverActivity)
      })

      this.$signalR.onServerActivityUpdate(this.server, (serverActivity) => {
        const old = this.activities.find(activity => activity.id === serverActivity.id)

        if (old !== null) {
          Object.assign(old, serverActivity)
        }
      })
    }

    @Watch('tableOptions', { deep: true })
    private onOptionsChanged() {
      this.start = 0
      this.activities = []
      this.loadMore()
    }

    private async loadMore() {
      let gotNewActivities = false
      this.busy = true

      const params = {
        start: this.start,
        amount: this.amount,
        orderByProperty: this.tableOptions.sortBy[0],
        ascending: !this.tableOptions.sortDesc[0]
      }

      const response = await this.$http.get(`/servers/${this.server.id}/activities`, { params })

      for (const activity of response.data) {
        gotNewActivities = true
        this.activities.push(plainToClass(ServerActivity, activity))
      }

      this.start += this.amount
      if (gotNewActivities) {
        this.busy = false
      }
    }
}

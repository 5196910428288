
import NxaAlert from '@/components/NxaAlert.vue'
import NxaServerStatus from '@/components/NxaServerStatus.vue'
import ServerStatusFilter from '@/components/ServerStatusFilter.vue'
import ServerTypeView from '@/components/ServerTypeView.vue'
import { Account } from '@/models/account'
import { Group } from '@/models/group'
import { Server } from '@/models/server'
import { ServerStatus } from '@/models/server-status'
import { getOrgSlug, getUser, isConsumer } from '@/services/Environment'
import ServerRegistration from '@/views/Servers/ServerRegistration.vue'
import { TranslateResult } from 'vue-i18n/types'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { DataTableHeader } from 'vuetify'
import EditServer from './EditServer.vue'
import ItemStatusLabel from '@/components/ItemStatusLabel.vue'

@Component({
  components: {
    ServerStatusFilter,
    NxaServerStatus,
    ServerTypeView,
    NxaAlert,
    ServerRegistration,
    EditServer,
    ItemStatusLabel
  }
})
export default class ServerList extends Vue {
  private isLoading = false
  private showDeleteDialog = false
  private isServerDeleting = false
  private servers: Server[] = []
  private search = ''
  private updateUptimeInterval: NodeJS.Timeout
  private user: Account
  private statusFilter: ServerStatus[] = []
  groups: Group[] = []
  headers: DataTableHeader[] = []
  private noDataInstructions: TranslateResult = this.$t('table.no-data.start-bms')
  private latestConnectorVersion: string

  public get selectedGroup(): number {
    return +this.$route.query.group
  }

  public set selectedGroup(groupId: number) {
    let route: RawLocation
    if (groupId == null || isNaN(groupId)) {
      route = { path: '', query: {} }
    } else {
      route = { path: '', query: { group: groupId.toString() } }
    }

    if (
      this.$route.path !== route.path &&
      this.$route.query.group !== route.query.group
    ) {
      this.$router.push(route)
    }
  }

  @Watch('$route.params')
  public async onRouteChanged() {
    await this.loadServers()
    this.initTableHeaders()
  }

  public beforeDestroy() {
    clearInterval(this.updateUptimeInterval)
    for (const server of this.servers) {
      server.destroy()
    }
  }

  public async created() {
    this.isLoading = true

    this.$root.$on('reload_servers', () => {
      this.loadServers()
    })

    try {
      this.latestConnectorVersion = await this.$backend.get('/servers/latest-connector-version')
      this.updateUptimeInterval = setInterval(() => this.updateUptime(), 1000)
      this.user = getUser()

      if (isConsumer()) {
        this.noDataInstructions = this.$t('table.no-data.consumer')
      }

      this.selectedGroup = +this.$route.query.group
      await this.loadServers()
      this.initTableHeaders()
      this.groups = await this.$backend.list(
        `/group/organization/${getOrgSlug()}`,
        Group
      )
    } catch (ex) {
      // tslint:disable-next-line: no-console
      console.error(ex)
    } finally {
      this.isLoading = false
    }
  }

  public rowClick(item) {
    const orgSlug = getOrgSlug()
    if (this.user.organization.slug === orgSlug || item.allowSupportAccess) {
      // prevent navigation for exe_agents of different organization
      this.$router.push(`/${orgSlug}/servers/${item.slug}`)
    }
  }

  private async loadServers() {
    const orgSlug = getOrgSlug()

    this.servers = await this.$backend.list(
      `/servers/organization/${orgSlug}`,
      Server
    )
    for (const server of this.servers) {
      server.init(orgSlug, this.$signalR)
      server.onDeleted((toDelete: Server) => {
        const index = this.servers.indexOf(toDelete, 0)
        if (index > -1) {
          this.servers.splice(index, 1)
        }
      })
    }
  }

  private updateUptime() {
    for (const server of this.servers) {
      server.updateUptimeString()
    }
  }

  private initTableHeaders(): void {
    const orgSlug = getOrgSlug()
    if (this.user.organization.slug !== orgSlug) {
      // hide unnecessary server information from exe_agent
      this.headers = [
        { text: '', value: 'allowSupportAccess', width: 1 },
        { text: this.$t('table.server_list.name').toString(), value: 'name' },
        { text: this.$t('info.license').toString(), value: 'licenseId' },
        { text: this.$t('table.server_list.type').toString(), value: 'type', sortable: false },
        { text: this.$t('table.server_list.seen').toString(), value: 'lastPing' }
      ]
    } else {
      this.headers = [
        { text: '', value: 'info', width: 1 },
        { text: this.$t('table.server_list.status').toString(), value: 'status', sortable: false },
        { text: this.$t('table.server_list.type').toString(), value: 'type', sortable: false },
        { text: this.$t('table.server_list.name').toString(), value: 'name' },
        { text: this.$t('group.title').toString(), value: 'serverGroups', filter: this.groupFilter },
        { text: this.$t('table.server_list.seen').toString(), value: 'lastPing' },
        { text: this.$t('table.server_list.uptime').toString(), value: 'uptimeString' },
        { text: this.$t('table.server_list.workspace').toString(), value: 'workspaceName' },
        { text: this.$t('cloudproject.title').toString(), value: 'cloudProjectName' }
      ]

      if (isConsumer()) {
        const disallowedSections = ['serverGroups', 'info']
        this.headers = this.headers.filter(x => !disallowedSections.includes(x.value))
      }
    }
  }

  private groupFilter(value) {
    if (!this.selectedGroup) {
      return true
    }
    return value.some((x) => x.group.id === this.selectedGroup)
  }

  public data() {
    return {
      ServerStatus
    }
  }

  public statusFilterChanged(statusFilter) {
    this.statusFilter = statusFilter
  }

  get filteredServers() {
    if (this.statusFilter.length === 0) return this.servers
    return this.servers.filter((server) =>
      this.statusFilter.some((status) => status !== ServerStatus.Warning && status === server.status))
      .filter((server) =>
        this.statusFilter.indexOf(ServerStatus.Warning) === -1 ||
            (this.statusFilter.indexOf(ServerStatus.Warning) > -1 && server.hasWarning)
      )
  }

  public async deleteServer(server: Server) {
    this.isServerDeleting = true
    server.isDeleted = true
    await this.$backend.delete(
      `/servers/organization/${server.orgSlug}/${server.slug}`
    )
    this.showDeleteDialog = false
    await this.loadServers()
  }

  public localServerFound() {
    if (isConsumer()) {
      this.noDataInstructions = this.$t('table.no-data.consumer')
    } else {
      this.noDataInstructions = this.$t('table.no-data.register')
    }
  }

  public isConsumerAccount() {
    return isConsumer()
  }
}

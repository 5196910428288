
import BackupDialog from './BackupDialog.vue'
import { Server } from '@/models/server'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BackupRestoreMessage } from '@/models/backupRestore/restore-backup-message'
import { BackupRestoreDownloadProgressMessage } from '@/models/backupRestore/backup-restore-download-progress-message'
import { BackupRestoreRestartingMessage } from '@/models/backupRestore/backup-restore-restarting-message'
import { CloudMessagWrapper } from '@/models/cloud-message-wrapper'
import { BackupRestoreErrorMessage } from '@/models/backupRestore/backup-restore-error-message'

@Component({
  components: {
    BackupDialog
  }

})
export default class BackupRestoreCard extends Vue {
    private resetting = false
    private restoreInProgress = false
    private downloading = false
    private fileSize = 0
    private transferredSize = 0
    private currentState = ''
    private originalWorkspaceName = ''
    private newWorkspaceName = ''

    @Prop()
    private server: Server

    public async created() {
      this.$signalR.onRestoreBackupMessage(this.server, (messageWrapper) => {
        this.handleRestoreBackupMessage(messageWrapper)
      })

      if (this.server.backupRestoreActive) {
        this.loadCurrentRestoreBackupMessage()
      }
    }

    private async loadCurrentRestoreBackupMessage() {
      const response = await this.$backend.get(`/servers/${this.server.id}/current-restore-backup-message`, CloudMessagWrapper)
      this.handleRestoreBackupMessage(response)
    }

    private handleRestoreBackupMessage(response: CloudMessagWrapper) {
      if (response == null) {
        this.restoreInProgress = false
        return
      }

      console.warn(response.messageType)

      if (response.messageType === 'BackupRestoreErrorMessage') {
        this.handleBackupRestoreErrorMessage(response.message as BackupRestoreErrorMessage)
        return
      }

      const restoreMessage = response.message as BackupRestoreMessage

      this.restoreInProgress = true
      this.originalWorkspaceName = restoreMessage.originalWorkspaceName
      this.fileSize = restoreMessage.fileSize
      switch (response.messageType) {
        case 'RequestBackupRestoreMessage': {
          this.fileSize = restoreMessage.fileSize
          this.transferredSize = 0
          this.downloading = false
          break
        }
        case 'BackupRestoreDownloadProgressMessage': {
          this.currentState = 'download'
          this.transferredSize = (response.message as BackupRestoreDownloadProgressMessage).transferredSize
          this.downloading = true
          break
        }
        case 'BackupRestoreInProgressMessage': {
          this.currentState = 'restore'
          this.downloading = false
          break
        }
        case 'BackupRestoreRestartingMessage': {
          this.currentState = 'restart'
          this.newWorkspaceName = (response.message as BackupRestoreRestartingMessage).newWorkspaceName
          this.downloading = false
          break
        }
        case 'BackupRestoreFinishedMessage': {
          this.currentState = 'restarted'
          this.downloading = false
          this.newWorkspaceName = (response.message as BackupRestoreRestartingMessage).newWorkspaceName
          break
        }
        case 'CancelBackupRestoreMessage': {
          this.currentState = 'canceled'
          this.downloading = false
          break
        }
        default: this.restoreInProgress = false
      }
    }

    private handleBackupRestoreErrorMessage(message: BackupRestoreErrorMessage) {
      this.restoreInProgress = true
      this.downloading = false
      switch (message.type) {
        case 'errorDisconnected': {
          this.currentState = 'errorDisconnected'
          break
        }
        case 'failedToStartWorkspace':
        {
          this.currentState = 'failedToStartWorkspace'
          break
        }
        default: {
          this.currentState = 'error'
          break
        }
      }
    }

    private async resetCurrentRestore() {
      this.resetting = true
      await this.$backend.get(`/servers/${this.server.id}/reset-current-backup-restore`)
      this.resetting = false
      this.restoreInProgress = false
    }

    public async cancelBackupRestore() {
      this.resetting = true
      await this.$backend.get(`/servers/${this.server.id}/cancel-current-backup-restore`)
      this.resetting = false
      await this.loadCurrentRestoreBackupMessage()
    }

    private downloadProgress(): number {
      if (this.transferredSize > 0 && this.fileSize > 0) {
        return 100 * this.transferredSize / this.fileSize
      }
      return 0
    }

    private bytesToMb(bytes: number) {
      return Math.round(100 * bytes / 1024.0 / 1024.0) / 100.0
    }
}

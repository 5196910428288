
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Server } from '@/models/server'
import { LogFileOptions } from '@/models/log-file-options'

@Component
export default class BackupDialog extends Vue {
    private backupErrorMessage: string = null
    private LogFileOptionsEnum = LogFileOptions
    private logFileOption = LogFileOptions.None
    private xlogicFiles = true
    private nxaVersionOutput = true

    @Prop()
    private server: Server

    @Prop()
    private visible = false

    public async createBackup() {
      this.$emit('createBackup', this.logFileOption, this.xlogicFiles, this.nxaVersionOutput)
    }

    private closeDialog() {
      this.$emit('close')
    }
}


import { Server } from '@/models/server'
import { ServerStatus } from '@/models/server-status'
import { getOrgSlug } from '@/services/Environment'
import NxaServerDialog from '@/views/Servers/ServerDetailComponents/NxaServerDialog.vue'
import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapSymbolLayer,
  atlas
} from 'vue-azure-maps'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    NxaServerDialog,
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer
  }
})
export default class ServerMap extends Vue {
    private servers: Server[] = []
    private selectedServers: Server[] = []
    private isLoading = false

    private onlineIcons = {
      filter: ['all',
        ['==', ['get', 'status'], ServerStatus.Online],
        ['!', ['get', 'hasWarning']]],
      iconOptions: {
        ignorePlacement: true,
        allowOverlap: true,
        image: 'server-online'
      }
    }

    private offlineIcons = {
      filter: ['all',
        ['==', ['get', 'status'], ServerStatus.Offline],
        ['!', ['get', 'hasWarning']]],
      iconOptions: {
        ignorePlacement: true,
        allowOverlap: true,
        image: 'server-offline'
      }
    }

    private warningIcons = {
      filter: ['get', 'hasWarning'],
      iconOptions: {
        ignorePlacement: true,
        allowOverlap: true,
        image: 'server-warning'
      }
    }

    private simulationIcons = {
      filter: ['all',
        ['==', ['get', 'status'], ServerStatus.Simulation],
        ['!', ['get', 'hasWarning']]],
      iconOptions: {
        ignorePlacement: true,
        allowOverlap: true,
        image: 'server-simulation'
      }
    }

    private standbyIcons = {
      filter: ['all',
        ['==', ['get', 'status'], ServerStatus.Standby],
        ['!', ['get', 'hasWarning']]],
      iconOptions: {
        ignorePlacement: true,
        allowOverlap: true,
        image: 'server-standby'
      }
    }

    public async created() {
      this.isLoading = true
      this.$root.$on('reload_servers', () => {
        this.loadServers()
      })
      await this.loadServers()
      this.isLoading = false
    }

    public async mapReady(e: atlas.MapEvent) {
      await e.map.imageSprite.createFromTemplate('server-online', 'pin', '#7dba78')
      await e.map.imageSprite.createFromTemplate('server-offline', 'pin', '#cc5956')
      await e.map.imageSprite.createFromTemplate('server-warning', 'pin', '#f7ac69')
      await e.map.imageSprite.createFromTemplate('server-simulation', 'pin', '#9ca8b5')
      await e.map.imageSprite.createFromTemplate('server-standby', 'pin', '#4991ad')
    }

    private async loadServers() {
      const orgSlug = getOrgSlug()
      if (!orgSlug) {
        return
      }

      this.servers = await this.$backend.list(`/servers/organization/${orgSlug}`, Server)
      this.servers = this.servers.filter(server => server.coordsValid())
      for (const server of this.servers) {
        server.init(orgSlug, this.$signalR)
      }
    }

    private async markerClicked(e: atlas.MapTouchEvent) {
      this.selectedServers = []
      for (const shape of e.shapes) {
        this.selectedServers.push(
          this.servers.find((server) => server.id === shape.getProperties().id)
        )
      }
    }
}

import { i18n } from '@/main'
import Vue from 'vue'

const _getErrorMessage = (code = 'internal') => {
  const key = `error.${code}`
  const message = i18n.t(key)

  if (message === key) {
    return null
  }

  return message
}

const getErrorMsg = (code = 'internal') => {
  let message = _getErrorMessage(code)

  if (message == null) {
    message = i18n.t('error.internal') as string // no translation found
  }

  return message as string
}

const showError = (code = 'internal') => {
  const message = _getErrorMessage(code)

  if (message == null) {
    Vue.prototype.messages.$emit('fatal', i18n.t('error.internal') as string)
  } else {
    Vue.prototype.messages.$emit('error', message)
  }
}

export { getErrorMsg, showError }

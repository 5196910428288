import { Role } from './role'

export class Invitation {
  public email: string
  public name: string
  public ok: boolean
  public errorString: string
  public organizationId: number
  public role: Role

  constructor(email: string, name: string, ok: boolean, errorString: string, role: Role, organizationId: number) {
    this.email = email
    this.name = name
    this.ok = ok
    this.errorString = errorString
    this.role = role
    this.organizationId = organizationId
  }

  static blank(role: Role, organizationId: number) {
    return new Invitation('', '', false, null, role, organizationId)
  }

  isEmailValid(): boolean {
    const regexLiteral = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regexLiteral.test(this.email)
  }
}

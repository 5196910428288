
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Server } from '@/models/server'
import NxaAlert from '@/components/NxaAlert.vue'

@Component({
  components: {
    NxaAlert
  }
})
export default class EditServer extends Vue {
    private isLoading = false
    private valid = false

    @Prop()
    private server: Server

    @Prop()
    private preventRedirect: boolean

    private localServer: Server = null

    private showDialog = false

    @Ref('form') private readonly form!: HTMLFormElement

    public edit() {
      this.localServer = Object.assign({}, this.server)
      this.showDialog = true
    }

    private async updateServer() {
      if (!this.form.validate()) {
        return
      }

      this.isLoading = true

      const server = await this.$backend.put(`/servers/${this.server.id}`, this.localServer, Server)
      if (!this.preventRedirect) {
        this.$router.push(
          `/${this.localServer.orgSlug}/servers/${server.slug}`
        )
      }
      this.server.name = server.name
      this.server.allowSupportAccess = server.allowSupportAccess
      this.showDialog = false

      this.isLoading = false
    }
}

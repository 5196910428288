import store from '@/plugins/account/store'
import { BackendPlugin } from '@/plugins/backend/backend-plugin'
import customDatatableSort from '@/plugins/datatables/datatables-plugin'
import { HttpPlugin } from '@/plugins/http/http-plugin'
import { ServerRegistrationPlugin } from '@/plugins/server-registration/server-registration-plugin'
import { SignalRPlugin } from '@/plugins/signalR/signalr-plugin'
import vuetify from '@/plugins/vuetify'
import { checkUserInactiv, getToken, initUser, onUserInteraction, renewToken } from '@/services/Environment'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { Moment } from 'moment'
import 'moment-timezone'
import Vue from 'vue'
import VueAzureMaps from 'vue-azure-maps'
import VueI18n from 'vue-i18n'
import infiniteScroll from 'vue-infinite-scroll'
import VueTestAttribute from 'vue-test-attribute'
import App from './App.vue'
import messages from './i18n/i18n'
import { RuntimeConfigPlugin } from './plugins/runtime-config/runtime-config-plugin'
import router from './router'

let browserLanguage = navigator.language.split('-')[0] // en-US => en
if (!Object.keys(messages).find((lang) => browserLanguage === lang)) {
  browserLanguage = 'en'
}

export const i18n = new VueI18n({ locale: browserLanguage, messages })

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

RuntimeConfigPlugin().then(async(runtimeConfig) => {
  Vue.use(VueAzureMaps, {
    key: runtimeConfig.AZURE_MAP_KEY
  })
  Vue.use(SignalRPlugin, runtimeConfig)
  Vue.use(HttpPlugin, runtimeConfig)
  Vue.use(BackendPlugin)
  Vue.use(ServerRegistrationPlugin, runtimeConfig)
  Vue.use(infiniteScroll)
  Vue.use(VueTestAttribute, runtimeConfig)

  Vue.filter('formatDateTime', (value: Moment) =>
    value !== null && typeof value !== 'undefined' ? value.clone().locale(navigator.language).tz(timeZone).format('L LTS') : ''
  )

  const token = getToken()

  window.addEventListener('keypress', onUserInteraction)
  window.addEventListener('keydown', onUserInteraction)
  window.addEventListener('keyup', onUserInteraction)
  window.addEventListener('mousedown', onUserInteraction)
  window.addEventListener('touchstart', onUserInteraction)

  if (token) {
    renewToken()
    Vue.prototype.startSignalR(token)
    await initUser()
  }

  Vue.prototype.messages = new Vue()

  Vue.mixin({
    data: () => ({ appVersion: runtimeConfig.VERSION }),
    methods: {
      customDatatableSort
    }
  })

  const inteval = setInterval(function() {
    checkUserInactiv()
  }, 5000)

  new Vue({
    i18n,
    router,
    vuetify,
    store,
    render: (h) => h(App)
  }).$mount('#app')
})


import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { Account } from '@/models/account'
import NxaAlert from '@/components/NxaAlert.vue'
import { Role } from '@/models/role'
import { Server } from '@/models/server'
import { getUser, isAdmin, logOut } from '@/services/Environment'
import { AccountServer } from '@/models/account-server'

@Component({
  components: {
    NxaAlert
  }
})
export default class EditUser extends Vue {
    private valid = false
    @Prop() private account!: Account
    @PropSync('showDialog')
    private showDialogSync

    @Ref('form') private readonly form!: HTMLFormElement
    private editAccount: Account = null

    private allRoles: Role[]
    private orgServers: Server[] = []
    private rolesDisabled = false
    private isLoading = false
    private user: Account
    private showNameChangeInfo = false

    private loadingHookTriggered = false;

    public selectableAccountServers: Server[] = []

    private async created() {
      this.isLoading = true
      this.allRoles = await this.$backend.list('/roles/assignable-roles', Role)
      this.user = getUser()
    }

    private async submitEditUser() {
      if (!this.form.validate()) {
        return
      }

      this.isLoading = true

      const nameChanged = this.user.id === this.editAccount.id
      if (nameChanged && !this.showNameChangeInfo) {
        this.isLoading = false
        this.showNameChangeInfo = true
        return
      }

      const accountServers = []
      for (const it of this.selectableAccountServers) {
        const assignServer = new AccountServer()
        assignServer.serverId = it.id
        assignServer.accountId = this.account.id
        assignServer.server = it
        accountServers.push(assignServer)
      }

      this.editAccount.accountServers = accountServers

      await this.$backend.put('/accounts', this.editAccount)
      Object.assign(this.account, this.editAccount)

      this.showDialogSync = false
      if (nameChanged) {
        await logOut()
      }
      this.isLoading = false
    }

    @Watch('showDialog')
    private async onShowDialogChanged() {
      if (this.showDialogSync === true) {
        this.loadingHookTriggered = true
        this.isLoading = true

        this.editAccount = { ...this.account }
        this.orgServers = await this.$backend.list(`/servers/organization/${this.account.organization.slug}`, Server)
        this.selectableAccountServers = this.account.accountServers.map(x => x.server)

        this.showNameChangeInfo = false
        this.rolesDisabled = !isAdmin() && this.editAccount.role.name.startsWith('exe_')

        this.loadingHookTriggered = false
        this.isLoading = false
      }
    }
}

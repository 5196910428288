import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Account } from '@/models/account'
import store from '@/plugins/account/store'

@Module({ dynamic: true, store, name: 'accountContainer' })
export default class AccountContainer extends VuexModule {
    private storedAccount: Account | null = null

    @Action({ commit: 'setAccountMutation' })
    public async setAccount(account: Account) {
      return account
    }

    @Mutation
    private setAccountMutation(account: Account) {
      this.storedAccount = account
    }

    public get account(): Account {
      return this.storedAccount
    }
}


import { PasswordChange } from '@/models/password-change'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { Account } from '@/models/account'

@Component
export default class ChangePasswordDialog extends Vue {
  @PropSync('showDialog') private showDialogSync: boolean
  @Prop() private account: Account

  private isLoading = false
  private password = ''
  private passwordOld = ''
  private passwordRepeat = ''

  private async change() {
    this.isLoading = true
    if (this.password === this.passwordRepeat) {
      const data = new PasswordChange(this.passwordOld, this.password)
      const result = await this.$backend.post(`/accounts/organization/${this.account.organization.slug}/${this.account.slug}/change-password`, data)
      if (result !== null) {
        Vue.prototype.messages.$emit('notification', this.$t('password.changed_successfully'))
        setTimeout(() => {
          this.showDialogSync = false
        }, 1000)
      }
    } else {
      Vue.prototype.messages.$emit('error', this.$t('password.not_equal'))
    }
    this.isLoading = false
  }

  @Watch('showDialog')
  private onShowDialogChanged() {
    if (this.showDialogSync === false) {
      this.password = ''
      this.passwordOld = ''
      this.passwordRepeat = ''
    }
  }
}

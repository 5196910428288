
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import RFB from '@novnc/novnc/core/rfb'

const wsProtocols: string[] = ['binary', 'base64']

@Component
export default class VncViewer extends Vue {
  private isLoading = true
  @Ref('view')
  private view!: HTMLElement

  @Prop(Boolean)
  private standalone: boolean

  private password = ''
  private promptPassword = false
  private connected = false
  private rfb: RFB
  private connectionTryCount: number
  private canvasSize: [string, string] = null

  private url: string

  public async connect(url: string): Promise<void> {
    this.url = url
    this.isLoading = true
    await this.timeout(1000)
    this.connectionTryCount = 0
    await this.waitForConnection(`${url}/websockify`)
    this.isLoading = false

    this.rfb = new RFB(this.view, url, { wsProtocols })
    this.rfb.scaleViewport = true
    this.rfb.addEventListener('connect', this.vncConnected)
    this.rfb.addEventListener('disconnect', this.vncDisconnected)
    this.rfb.addEventListener('credentialsrequired', this.vncCredentialsRequired)
    this.rfb.addEventListener('securityfailure', this.securityFailed)

    document.addEventListener('fullscreenchange', this.onFullScreenChange, false)
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChange, false)
    document.addEventListener('mozfullscreenchange', this.onFullScreenChange, false)
  }

  private async reconnect(): Promise<void> {
    await this.connect(this.url)
  }

  private onFullScreenChange() {
    if (document.fullscreenElement === null && this.canvasSize !== null) {
      const canvas = this.getVncCanvas()
      // restore canvas size, vnc client will then rescale
      canvas.style.width = this.canvasSize[0]
      canvas.style.height = this.canvasSize[1]
      this.canvasSize = null
    }
  }

  private async waitForConnection(url: string): Promise<void> {
    let connected = false

    try {
      const ws = new WebSocket(url, wsProtocols)
      ws.addEventListener('open', () => {
        ws.close()
        connected = true
      })
    } catch (e) {
      // Websocket not ready yet
    }

    await this.timeout(1000)

    if (!connected) {
      if (this.connectionTryCount < 3) {
        this.connectionTryCount++
        await this.waitForConnection(url)
      } else {
        this.close()
      }
    }
  }

  private async timeout(ms): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  private close() {
    this.promptPassword = false
    this.password = ''
    this.$emit('closed')
    if (this.rfb) {
      this.rfb.disconnect()
    }
  }

  private fullscreen() {
    this.view.requestFullscreen()
    const canvas = this.getVncCanvas()
    this.canvasSize = [canvas.style.width, canvas.style.height]
  }

  private getVncCanvas() {
    return this.view.getElementsByTagName('canvas')[0]
  }

  private vncConnected() {
    this.connected = true
  }

  private vncDisconnected() {
    this.connected = false
  }

  private vncCredentialsRequired() {
    this.promptPassword = true
  }

  private securityFailed(e: CustomEvent) {
    const reason: string = e.detail.reason
    if (reason.includes('Authentication')) {
      Vue.prototype.messages.$emit('error', this.$t('error.wrong_password'))
      this.vncCredentialsRequired()
    } else {
      Vue.prototype.messages.$emit('error', reason)
      this.close()
    }
  }

  private sendPassword() {
    this.promptPassword = false
    this.rfb.sendCredentials({ password: this.password })
  }

  private openInNewWindow() {
    const routeData = this.$router.resolve({ name: 'server.vnc' })
    window.open(routeData.href, '_blank')
  }
}

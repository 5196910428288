
import NxaAlert from '@/components/NxaAlert.vue'
import { Auth } from '@/models/auth'
import { BackendStatus } from '@/models/backend-status'
import { initUser, setToken } from '@/services/Environment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MessageSnackbar from '@/components/MessageSnackbar.vue'
import { LoginResponse } from '@/models/LoginResponse'

@Component({
  components: {
    MessageSnackbar,
    NxaAlert
  }
})
export default class Join extends Vue {
  public isLoading = false
  public error: string = null
  public status: BackendStatus = null

  public passwordRepeat = ''

  private account: Auth = null

  @Prop({ default: false })
  private isPwdReset: boolean

  public async created() {
    this.status = await this.$backend.get('/status', BackendStatus)
    this.account = await this.$backend.get(`/accounts/from-token/${this.$route.params.token}`)
    if (this.account == null) {
      await this.$router.push('../login')
    }
  }

  public async register() {
    this.isLoading = true
    this.account.forgotPassword = true

    try {
      if (this.account.password !== this.passwordRepeat) {
        this.error = this.$t('password.not_equal').toString()
        return
      }

      this.account.token = this.$route.params.token

      if (this.isPwdReset) {
        if (await this.$backend.post('/accounts/reset-password', this.account) == null) {
          return
        }
      } else {
        if (await this.$backend.post('/accounts/register', this.account) == null) {
          return
        }
      }

      const response = await this.$backend.post('/identity/login', this.account) as LoginResponse
      const token = response.token
      setToken(token)
      Vue.prototype.startSignalR(token)
      await initUser()

      if (this.$route.query.redirectTo) {
        const redirect = this.$route.query.redirectTo as string
        await this.$router.push(redirect)
      } else {
        await this.$router.push({ name: 'servers.list' })
      }
    } finally {
      this.isLoading = false
    }
    this.account.forgotPassword = false
  }
}

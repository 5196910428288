
import { Component, Vue } from 'vue-property-decorator'
import { Group } from '@/models/group'
import { getUser } from '@/services/Environment'
import moment from 'moment'

@Component
export default class GroupList extends Vue {
  private showDialog = false
  private group: Group = new Group()
  private isLoading = false
  private timeZones = moment.tz.names()

  public create() {
    this.group = new Group()
    this.group.timeZoneId = moment.tz.guess()
    this.showDialog = true
  }

  public edit(group: Group) {
    this.group = new Group()
    Object.assign(this.group, group)
    this.showDialog = true
  }

  private async addOrUpdateGroup() {
    this.group.organizationId = getUser().organization.id

    if (!this.group.id) {
      await this.$backend.post('/group', this.group)
    } else {
      await this.$backend.put('/group', this.group)
    }

    this.showDialog = false
    this.$emit('onChanged')
  }
}

import router from '@/router'
import Vue from 'vue'
import 'es6-shim'
import 'reflect-metadata'
import { getModule } from 'vuex-module-decorators'
import AccountContainer from '@/plugins/account/account-container'
import { Account } from '@/models/account'

const TOKEN = 'cloud_portal_token'
const accountContainer = getModule(AccountContainer)
let lastUserAction: number

const getToken = () => {
  return localStorage.getItem(TOKEN)
}

const setToken = (token: string) => {
  localStorage.setItem(TOKEN, token)
}

const renewToken = async() => {
  const result = await Vue.prototype.$backend.get('/identity/renew')
  if (result != null) {
    setToken(result)
  }
}

const clearToken = () => {
  localStorage.removeItem(TOKEN)
}

const initUser = async(): Promise<Account> => {
  const user = await Vue.prototype.$backend.get('/identity', Account)
  await accountContainer.setAccount(user)
  lastUserAction = Date.now()
  return user
}

const getUser = () => {
  return accountContainer.account
}

const renewTokenInterval = setInterval(async function() {
  if (getUser() != null) {
    await renewToken()
  }
}, 5 * 60 * 1000)

const onUserInteraction = () => {
  if (getUser() !== null) {
    lastUserAction = Date.now()
  }
}

const getOrgSlug = () => {
  return (router.currentRoute.params != null && router.currentRoute.params.orgSlug) ||
      accountContainer.account.organization.slug
}

const isAdmin = () => {
  return router.currentRoute.params != null &&
      router.currentRoute.params.orgSlug !== accountContainer.account.organization.slug
}

const isConsumer = () => {
  const acc = getUser()
  return acc.role.name === 'consumer'
}

const isOrgMember = () => {
  const acc = getUser()
  return acc.role.name === 'org_member'
}

const logOut = async() => {
  clearToken()
  await accountContainer.setAccount(null)
  await router.push('/login')
}

const checkUserInactiv = async() => {
  const user = getUser()
  if (user != null && user.inactivityTimeout > 0 && Date.now() - lastUserAction > user.inactivityTimeout * 1000) {
    clearToken()
    await accountContainer.setAccount(null)
    router.push({ path: '/login', query: { reason: 'session_expired' } })
  }
}

export { getToken, setToken, clearToken, logOut, initUser, getUser, getOrgSlug, isAdmin, isConsumer, isOrgMember, onUserInteraction, checkUserInactiv, renewToken }

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.account)?_c(VDialog,{attrs:{"height":"50vh","width":"50vw","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showDialogSync=false}},model:{value:(_vm.showDialogSync),callback:function ($$v) {_vm.showDialogSync=$$v},expression:"showDialogSync"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-clock")]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("account.set_logout_timeout"))+": "+_vm._s(_vm.account.name))]),_c(VSpacer)],1),_c(VCardText,[_c(VForm,[_c(VSwitch,{attrs:{"label":_vm.$t('account.auto_logout_enabled')},model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}}),(_vm.enabled)?_c(VTextField,{attrs:{"id":"passwordOld","name":"inactivityTimeout","label":_vm.$t("account.logout_timeout").toString(),"type":"number"},model:{value:(_vm.timeout),callback:function ($$v) {_vm.timeout=$$v},expression:"timeout"}}):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialogSync=false}}},[_vm._v(_vm._s(_vm.$t('button.close')))]),_c(VBtn,{directives:[{name:"test",rawName:"v-test",value:('btn-change'),expression:"'btn-change'"}],attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.change}},[_vm._v(_vm._s(_vm.$t('button.change')))])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
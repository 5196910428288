
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ServerStatus } from '@/models/server-status'
import { Server } from '@/models/server'

@Component
export default class ServerStatusFilter extends Vue {
  @Prop()
  private servers: Server[]

  private filter = []

  private count(status: ServerStatus) {
    return this.servers.filter(server => server.status === status).length
  }

  public filterChanged() {
    this.$emit('filter-changed', this.filter)
  }

  public data() {
    return {
      ServerStatus
    }
  }
}

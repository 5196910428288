import { ItemStatus } from '@/models/item-status'
import { ServerGroup } from '@/models/server-group'
import { ServerStatus } from '@/models/server-status'
import { SignalR } from '@/plugins/signalR/signalr'
import { Expose, plainToClass, Transform, Type } from 'class-transformer'
import moment, { Moment } from 'moment'
import Vue from 'vue'
import { LogMessage } from './log-message'
import { ServerItem } from './server-item'
import { ServerFamily } from '@/models/server-family'
import { ServerType } from './server-type'
import { BackupRestoreMessage } from './backupRestore/restore-backup-message'
import { CloudProject } from './cloud-project'

export class Server {
    public static readonly CONNECTION_BREAK_THRESHOLD_SEC = 15;

    public id: number
    public name: string
    public licenseId: string
    public position: string
    public location: string
    public isDeleted: boolean
    public status: ServerStatus = ServerStatus.Offline
    @Expose({ name: 'serverType' })
    public serverFamily: ServerFamily

    public apiKey: string
    public isMainServer: boolean
    public isBackupServer: boolean
    public type: ServerType = ServerType.StandAlone
    public hasWarning = false

    // workaround, should be: @Type(() => ServerItem)
    @Transform(value => {
      const map = new Map<string, ServerItem>()
      for (const entry of Object.entries(value)) {
        map.set(entry[0], plainToClass(ServerItem, entry[1]).init())
      }
      return map
    }, { toClassOnly: true })
    public items: Map<string, ServerItem> = new Map<string, ServerItem>()

    public logs: LogMessage[] = []
    public availableRemoteServices: string[] = []
    public webManagerStandby = false
    public webServerStandby = false
    @Transform((value) => moment(value))
    public lastPing: Moment = moment()

    public uptimeString = ''
    public organizationId = ''
    public slug = ''
    public orgSlug = ''
    public backupActive = false
    public backupRestoreActive = false
    public backupUploadTimeout = 0
    @Transform((value) => moment(value))
    public startup: Moment

    @Transform((value) => moment.tz(value, 'UTC'))
    public lastBackup: Moment

    public isOnline: boolean
    public workspaceName: string
    public longitude: number
    public latitude: number
    public connectorVersion: string = null
    public allowSupportAccess: boolean
    public cloudProjectName = ''
    public cloudProjectAssigned = false
    public cloudProjectWrongWorkspace = false
    @Type(() => ServerGroup)
    public serverGroups: ServerGroup[]

    @Type(() => CloudProject)
    public backupServerCloudProjects: CloudProject[]

    @Type(() => CloudProject)
    public mainServerCloudProjects: CloudProject[]

    @Type(() => BackupRestoreMessage)
    public lastRestoreBackupMessage: BackupRestoreMessage

    private _signalR: SignalR = null

    public init(orgSlug: string, signalR: SignalR) {
      this.orgSlug = orgSlug
      this.updateLocation()

      this.setFieldsFromServerItems()
      this.updateServerStatus()
      this.connectSignalR(signalR)
    }

    public getItemArray(): ServerItem[] {
      if (this.items == null) return []
      return Array.from(this.items.values())
    }

    public destroy() {
      this.unregisterEvents()
    }

    public updateItem(item: ServerItem) {
      if (this.items.has(item.item)) {
        const existing = this.items.get(item.item)
        existing.update(item)
      } else {
        this.items.set(item.item, item)
      }

      this.setFieldsFromServerItem(item)
    }

    public updateUptimeString() {
      if (!this.startup) {
        this.uptimeString = ''
        return
      }
      const now = moment()
      const uptime = moment.duration(now.diff(this.startup))

      const days = Math.round(uptime.asDays())
      const hours = uptime.hours().toString().padStart(2, '0')
      const minutes = uptime.minutes().toString().padStart(2, '0')
      const seconds = uptime.seconds().toString().padStart(2, '0')

      this.uptimeString = days > 0 ? `${days}d ` : ''
      this.uptimeString += `${hours}h ${minutes}m ${seconds}s`
    }

    public async requestBackup() {
      if (this.workspaceName !== '') {
        await Vue.prototype.$http.post(`/servers/${this.id}/request-backup`, `"${this.workspaceName}"`)
      } else {
        throw new Error('workspace_not_set')
      }
    }

    public itemStatus(key: string) {
      if (this.items === undefined) return ItemStatus.Unknown

      if (!this.items.has(key) || (this.items.has(key) && typeof this.items.get(key).value === 'undefined')) {
        return ItemStatus.Unknown
      }
      if (this.itemHasValue(key, '2')) {
        return ItemStatus.Standby
      }
      return this.itemHasValue(key, '0') || this.itemHasValue(key, 'True') ? ItemStatus.Ok : ItemStatus.Warning
    }

    public canStartBackup() {
      return this.isOnline && !this.backupActive
    }

    public coordsValid() {
      return this.latitude != null && this.longitude != null && !isNaN(this.latitude) && !isNaN(this.longitude) && (this.latitude !== 0 || this.longitude !== 0)
    }

    public get isBmsPlatform() {
      return this.serverFamily === 'bmsPlatformServer'
    }

    public get isMpServer() {
      return this.serverFamily === 'multiProtocolServer'
    }

    public onDeleted(deleteAction: (toDelete: Server) => void) {
      this._signalR.onServerDeleted(this, () => deleteAction(this))
    }

    public isOutdated(latestConnectorVersion: string): boolean {
      if (this.connectorVersion == null || latestConnectorVersion == null) {
        return true
      }
      const connectorParts = this.connectorVersion.split('.')
      const latestParts = latestConnectorVersion.split('.')

      if (connectorParts.length === 0 || latestParts.length === 0) {
        return true
      }
      if (Number(connectorParts[0]) < 2000 && Number(latestParts[0]) > 2000) {
        return false
      }
      if (Number(connectorParts[0]) > 2000 && Number(latestParts[0]) < 2000) {
        return true
      }
      for (let i = 0; i < connectorParts.length && i < latestParts.length; i++) {
        if (Number(connectorParts[i]) < Number(latestParts[i])) {
          return true
        }
        if (Number(connectorParts[i]) > Number(latestParts[i])) {
          return false
        }
      }
      return false
    }

    private updateLocation() {
      // add 1.11 cm to keep azure maps from complaining if coords are 0
      if (this.longitude === 0 && this.latitude !== 0) {
        this.longitude += 0.0000001
      }
      if (this.latitude === 0 && this.longitude !== 0) {
        this.latitude += 0.0000001
      }
    }

    private itemHasValue(key: string, value: string): boolean {
      if (this.items == null) return false

      if (this.items.has(key)) {
        return this.items.get(key).hasValue(value)
      } else {
        return false
      }
    }

    private hasItemWithWarning() {
      let items = this.getItemArray()

      if (this.status === ServerStatus.Standby) {
        items = items.filter((item) => item.item !== ServerItem.databaseKey)
      }

      return items.some((serverItem) => serverItem.warning)
    }

    private updateServerStatus() {
      if (!this.isOnline) {
        this.status = ServerStatus.Offline
      } else if (this.isBackupServer && this.itemHasValue('NETx\\Server\\IsActive', 'False')) {
        this.status = ServerStatus.Standby
      } else if (this.itemHasValue('NETx\\Server\\IsOnline', 'False')) {
        // Server is in simulation mode
        this.status = ServerStatus.Simulation
      } else {
        this.status = ServerStatus.Online
      }

      if (this.isMainServer) {
        this.type = ServerType.Main
      } else if (this.isBackupServer) {
        this.type = ServerType.Backup
      } else if (this.isOnline) {
        this.type = ServerType.StandAlone
      } else {
        this.type = null
      }

      this.hasWarning = this.isOnline && this.hasItemWithWarning()
      this.updateCurrentCloudProjectName()
    }

    private updateCurrentCloudProjectName() {
      if (this.mainServerCloudProjects == null || this.backupServerCloudProjects == null || (this.mainServerCloudProjects.length === 0 && this.backupServerCloudProjects.length === 0)) {
        // Server is not assigned to any cloud project
        this.cloudProjectAssigned = false
        this.cloudProjectName = ''
        this.cloudProjectWrongWorkspace = false
        return
      }
      this.cloudProjectAssigned = true
      if (this.workspaceName == null || this.workspaceName === '') {
        // Server is assigned to a cloud project, but we don't know the curren workspace
        this.cloudProjectName = ''
        this.cloudProjectWrongWorkspace = false
        return
      }
      let projects = this.mainServerCloudProjects.filter(p => p.mainServerWorkspace === this.workspaceName)
      if (projects.length > 0) {
        this.cloudProjectName = projects[0].projectName
        this.cloudProjectWrongWorkspace = false
        return
      }
      projects = this.backupServerCloudProjects.filter(p => p.backupServerWorkspace === this.workspaceName)
      if (projects.length > 0) {
        this.cloudProjectName = projects[0].projectName
        this.cloudProjectWrongWorkspace = false
        return
      }
      this.cloudProjectName = ''
      this.cloudProjectWrongWorkspace = true
    }

    private connectSignalR(signalR: SignalR) {
      this._signalR = signalR

      this._signalR.onSessionChanged(this, async(server, isOnline) => {
        this.isOnline = isOnline
        this.lastPing = server.lastPing
        if (!isOnline) {
          this.status = ServerStatus.Offline
          this.startup = null
          this.workspaceName = ''
          this.items.clear()
          this.hasWarning = false
          this.availableRemoteServices = []
          this.updateUptimeString()
          this.updateCurrentCloudProjectName()
        }
      })

      this._signalR.onServerItems(this, (items) => {
        items
          .filter((item) => {
            return item.available === true
          })
          .forEach((item) => {
            this.updateItem(item)
          })

        this.updateServerStatus()
      })

      this._signalR.onServerLogs(this, (logs) => {
        if (logs) {
          this.logs = Array.from(
            new Map<Date, LogMessage>(
              [...this.logs, ...logs].map((i) => [i.logDate, i])
            ).values()
          )
        }
      })

      this._signalR.onAvailableRemoteServices(this, (services) => {
        if (services) {
          this.availableRemoteServices = services
        }
      })

      this._signalR.onServerInfo(this, (serverInfo) => {
        if (serverInfo) {
          this.licenseId = serverInfo.licenseId
          this.connectorVersion = serverInfo.connectorVersion
          this.longitude = serverInfo.locationLongitude
          this.latitude = serverInfo.locationLatitude
          this.location = serverInfo.location
          this.updateLocation()
        }
      })
    }

    private unregisterEvents() {
      if (this._signalR) {
        this._signalR.unregisterOnSessionChanged()
        this._signalR.unregisterOnServerItems()
        this._signalR.unregisterOnServerLog()
        this._signalR.unregisterOnServerDeleted()
        this._signalR.unregisterOnServerInfo()
      }
    }

    public setFieldsFromServerItems() {
      if (this.items === undefined) return
      this.items.forEach(item => this.setFieldsFromServerItem(item))
    }

    private setFieldsFromServerItem(item: ServerItem) {
      switch (item.item) {
        case 'NETx\\Server\\Version':
          this.startup = moment(item.lastChange)
          this.updateUptimeString()
          break

        case 'NETx\\Server\\WorkspaceName':
          this.workspaceName = item.value
          break

        case 'NETx\\Server\\License\\LicenseID"':
          this.licenseId = item.value
          break

        case 'NETx\\Server\\IsBackupServer':
          this.isBackupServer = item.value === 'True'
          break

        case 'NETx\\Server\\IsMainServer':
          this.isMainServer = item.value === 'True'
          break

        case 'NETx\\Server\\WebManager\\Status':
          this.webManagerStandby = item.value === '2'
          break

        case 'NETx\\Server\\WebServer\\Status':
          this.webServerStandby = item.value === '2'
          break
      }
    }
}


import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import { Account } from '@/models/account'
import * as base32 from 'hi-base32'
import VueQrcode from 'vue-qrcode'
import { getUser } from '@/services/Environment'
import { TfaDto } from '@/models/TfaDto'

@Component({
  components: {
    VueQrcode
  }
})
export default class TfaDialog extends Vue {
  @PropSync('showDialog') private showDialogSync: boolean
  private account: Account = new Account()
  public credentials: TfaDto = new TfaDto()

  private generatedKey = ''
  private qrCode = ''

  mounted() {
    this.init()
  }

  @Watch('showDialog')
  init() {
    this.account = getUser()
    this.qrCode = ''
    this.credentials = new TfaDto()

    if (!this.account.isTfaEnabled) {
      this.generateKey()
    }
  }

  private async createQRCode() {
    return `otpauth://totp/NETx%20Cloud%20Portal:${this.account.email}?secret=${this.credentials.tfaSecret}&issuer=NETxAutomation&algorithm=SHA1&digits=6&period=30`
  }

  private async generateKey() {
    const randomString = (length) => [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('')
    const secret = base32.encode(randomString(10)).substr(0, 16)
    this.credentials.tfaSecret = secret
    this.generatedKey = secret.match(/.{1,4}/g).join(' ')
    this.qrCode = await this.createQRCode()
  }

  private async storeSecret() {
    if (await this.$backend.post('/identity/store-secret', this.credentials)) {
      this.account.isTfaEnabled = true
      Vue.prototype.messages.$emit('notification', this.$t('notification.tfaActivated'))
      this.showDialogSync = false
    }
  }

  private async deleteSecret() {
    if (await this.$backend.post('/identity/delete-secret', this.credentials)) {
      this.account.isTfaEnabled = false
      Vue.prototype.messages.$emit('notification', this.$t('notification.secretKeyDeleted'))
      this.showDialogSync = false
    }
  }
}

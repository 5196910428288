import { JobConfig } from './job-config'
import { LogFileOptions } from './log-file-options'

export class BackupJobConfig implements JobConfig {
  public successNotification: boolean
  public failureNotification: boolean
  public keepAmount: number
  public logFileLevel: LogFileOptions
  public xlogic: boolean
  public nxaVersions: boolean
  public workspaceName: string
}

import _Vue from 'vue'
import { RuntimeConfig } from '@/plugins/runtime-config/runtime-config'
import { SignalR } from '@/plugins/signalR/signalr'

declare module 'vue/types/vue' {
    interface Vue {
        $signalR: SignalR;
    }
}

export function SignalRPlugin(Vue: typeof _Vue, config: RuntimeConfig) {
  Vue.prototype.startSignalR = (token) => {
    if (Vue.prototype.$signalR === undefined) {
      Vue.prototype.$signalR = new SignalR(token, config)
    }
  }
}

export class RemoteAccess {
    public id: number
    public cloudProjectId: number
    public name: string
    public type: string
    public active: boolean
    public guid: string
    public allowedDevices: string
    public token: string
    public link: string
}

import { Role } from '@/models/role'
import { Organization } from '@/models/organization'
import { Type } from 'class-transformer'
import { AccountServer } from './account-server'

export class Account {
    public id: number

    @Type(() => Organization)
    public organization: Organization

    public name: string
    public displayName: string
    public slug: string
    public email: string
    public isTfaEnabled: boolean
    public inactivityTimeout: number

    @Type(() => Role)
    public role: Role

    @Type(() => AccountServer)
    public accountServers?: AccountServer[]
}

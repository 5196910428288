
import DeleteConfirmationDialog from '@/components/DeleteConfirmationDialog.vue'
import { CloudProject } from '@/models/cloud-project'
import { TableHeader } from '@/models/table-header'
import EditCloudProject from './EditCloudProject.vue'
import { getOrgSlug } from '@/services/Environment'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    EditCloudProject,
    DeleteConfirmationDialog
  }
})
export default class CloudProjectList extends Vue {
    private cloudProjects: CloudProject[] = []
    private search = ''
    private showEditProjectDialog = false

    headers: TableHeader[] = [
      { text: this.$t('cloudproject.projectName'), value: 'projectName' },
      { text: this.$t('cloudproject.mainServer'), value: 'mainServer.name' },
      { text: this.$t('cloudproject.mainServerWorkspace'), value: 'mainServerWorkspace' },
      { text: this.$t('cloudproject.backupServer'), value: 'backupServer.name' },
      { text: this.$t('cloudproject.backupServerWorkspace'), value: 'backupServerWorkspace' },
      { text: this.$t('remote-access.remote-accesses'), value: 'remoteAccessCount' },
      { text: '', value: 'isAssignedToServer', align: 'right' }
    ]

    private cloudProject = new CloudProject()
    private isLoading = false

    private async created() {
      this.isLoading = true
      await this.loadCloudProjects()
      this.isLoading = false
    }

    private projectEditClicked(cloudProject: CloudProject) {
      this.cloudProject = cloudProject
      this.showEditProjectDialog = true
    }

    private createProjectClicked() {
      this.cloudProject = new CloudProject()
      this.showEditProjectDialog = true
    }

    private async loadCloudProjects() {
      this.cloudProjects = await this.$backend.list(`/cloudprojects/organization/${getOrgSlug()}`, CloudProject)
    }

    private async deleteProject(cloudProject: CloudProject) {
      await this.$backend.delete(`/cloudprojects/${cloudProject.id}`)
      await this.loadCloudProjects()
    }

    private listRemoteAccesses(cloudProject: CloudProject) {
      this.$router.push(`/${getOrgSlug()}/cloud-projects/${cloudProject.id}/remote-access-list`)
    }
}

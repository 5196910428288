
import { TableHeader } from '@/models/table-header'
import { isAdmin } from '@/services/Environment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Server } from '@/models/server'
import { ServerItem } from '@/models/server-item'

@Component
export default class ItemsTable extends Vue {
    @Prop()
    public server: Server

    public items: ServerItem[] = []
    public headers: TableHeader[] = [
      { text: this.$t('table.items.id'), value: 'key', sortable: false },
      { text: this.$t('table.items.description'), value: 'description', sortable: false },
      { text: this.$t('table.items.value'), value: 'value', sortable: false }
    ]

    public created() {
      this.items = isAdmin() ? [] : this.server.getItemArray()
    }

    @Watch('server', { deep: true })
    private onServerItemsChanged() {
      this.items = isAdmin() ? [] : this.server.getItemArray()
    }
}

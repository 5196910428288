import axios from 'axios'
import { RegistrationDetails } from '@/models/registration-details'
import { RuntimeConfig } from '@/plugins/runtime-config/runtime-config'
import { plainToClass } from 'class-transformer'
import { PluginInfo } from '@/models/PluginInfo'
import { ServerFamily } from '@/models/server-family'

const registrationPorts = [54297, 59742, 64259]
const registrationTimeout = 1000
const expectedText = 'listening for registration'

export class ServerRegistration {
  private endpoint: string
  private runtimeConfig: RuntimeConfig

  constructor(runtimeConfig: RuntimeConfig) {
    this.runtimeConfig = runtimeConfig
  }

  public async getAvailableServerFamily(): Promise<ServerFamily|null> {
    for (const port of registrationPorts) {
      const url = `http://localhost:${port}`
      try {
        const response = await axios.get(url, {
          timeout: registrationTimeout,
          headers: {
            'Accept-Language': undefined
          }
        })
        if (response.status !== 200) {
          continue
        }
        const info = plainToClass(PluginInfo, response.data)
        if (info === null || info.status !== expectedText) {
          continue
        }
        this.endpoint = url
        return info.family
      } catch (e) {
        // try next port
      }
    }
    return undefined
  }

  public async register(apiKey: string): Promise<boolean> {
    const registration: RegistrationDetails = {
      apiKey,
      backendUrl: `${this.runtimeConfig.API_ENDPOINT}/api`,
      cloudProxyUrlPostFix: this.runtimeConfig.CLOUD_PROXY_URL_POSTFIX ?? ''
    }
    const response = await axios.post(this.endpoint, registration, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': undefined
      }
    })
    return response.status === 200
  }
}

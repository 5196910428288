import { render, staticRenderFns } from "./ServerDetail.vue?vue&type=template&id=613b24c0&scoped=true&lang=pug&"
import script from "./ServerDetail.vue?vue&type=script&lang=ts&"
export * from "./ServerDetail.vue?vue&type=script&lang=ts&"
import style0 from "./ServerDetail.vue?vue&type=style&index=0&id=613b24c0&prod&lang=scss&scoped=true&"
import style1 from "./ServerDetail.vue?vue&type=style&index=1&id=613b24c0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613b24c0",
  null
  
)

export default component.exports
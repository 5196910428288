
import { TableHeader } from '@/models/table-header'
import { Component, Vue } from 'vue-property-decorator'
import { Organization } from '@/models/organization'
import AccountInvitationDialog from './AccountInvitationDialog.vue'
import EditOrganization from './EditOrganization.vue'
import DeleteOrganizationDialog from './DeleteOrganizationDialog.vue'
import NxaAlert from '@/components/NxaAlert.vue'
import moment, { Moment } from 'moment'

@Component({
  components: {
    AccountInvitationDialog,
    EditOrganization,
    DeleteOrganizationDialog,
    NxaAlert
  }
})
export default class Organizations extends Vue {
  private organizations: Organization[] = []
  private isLoading = true
  tableHeaders: TableHeader[]
  private search = ''

  private created() {
    this.initTableHeaders()
  }

  private onOrgDeleted(organizationId: number) {
    const index = this.organizations.findIndex((org) => org.id === organizationId)
    this.organizations.splice(index, 1)
  }

  private async onChanged() {
    await this.loadOrganizations()
  }

  private async mounted() {
    await this.loadOrganizations()
  }

  private async loadOrganizations() {
    this.isLoading = true
    this.organizations = await this.$backend.list('/admin/organizations', Organization)
    this.isLoading = false
  }

  private initTableHeaders() {
    this.tableHeaders = [
      { text: this.$t('table.organizations.name').toString(), value: 'name' },
      { text: this.$t('table.organizations.licenses').toString(), value: 'serverLicenseCount', sortable: false },
      { text: this.$t('table.organizations.registered').toString(), value: 'serverCount', sortable: false },
      { text: this.$t('table.organizations.connected').toString(), value: 'connectedServerCount', sortable: false },
      { text: this.$t('table.organizations.enabled').toString(), value: 'enabled', sortable: false },
      { text: this.$t('table.organizations.createdAt').toString(), value: 'createdAt', sortable: false },
      { text: this.$t('table.organizations.validUntil').toString(), value: 'validUntil', sortable: false },
      { text: this.$t('table.organizations.actions').toString(), value: 'action', sortable: false }
    ]
  }

  private listAccounts(organization: Organization) {
    this.$router.push(`/${organization.slug}/accounts`)
  }

  private listServers(organization: Organization) {
    this.$router.push(`/${organization.slug}/servers`)
  }

  private formatDate(date: Moment) {
    if (date == null) {
      return ''
    }
    return date.locale(navigator.language).format('L')
  }

  private isExpired(organization: Organization) {
    if (organization.validUntil == null) {
      return false
    }
    const duration = moment.duration(organization.validUntil.diff(moment.now())).asMonths()
    return duration <= 0
  }

  private willExpireSoon(organization: Organization) {
    if (organization.validUntil == null) {
      return false
    }
    const duration = moment.duration(organization.validUntil.diff(moment.now())).asMonths()
    return duration < 1
  }
}

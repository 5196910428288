import { ServerActivity } from '@/models/server-activity'
import { RuntimeConfig } from '@/plugins/runtime-config/runtime-config'
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@aspnet/signalr'
import { Server } from '@/models/server'
import { ServerItem } from '@/models/server-item'
import { LogMessage } from '@/models/log-message'
import { plainToClass } from 'class-transformer'
import 'es6-shim'
import 'reflect-metadata'
import { CloudMessagWrapper } from '@/models/cloud-message-wrapper'
import { ServerInfo } from '@/models/server-info'

export class SignalR {
    private connection: HubConnection
    private startedPromise: Promise<void>

    constructor(token: string, config: RuntimeConfig) {
      this.connect(token, config)
    }

    public connect(token: string, config: RuntimeConfig) {
      if (this.connection == null) {
        this.connection = new HubConnectionBuilder()
          .withUrl(`${config.API_ENDPOINT}/live/connections`, { accessTokenFactory: () => token })
          .configureLogging(LogLevel.Debug)
          .build()

        this.connection.onclose(() => {
          setTimeout(() => {
            this.start()
          }, 3000)
        })
      }

      this.start()
    }

    public onClosed(callback: () => void) {
      this.connection.onclose(() => callback())
    }

    public onConnected(server: Server, callback: () => void) {
      this.connection.on('connected', (conn) => {
        if (server.id === conn.id) {
          callback()
        }
      })
    }

    public onDisconnected(server: Server, callback: () => void) {
      this.connection.on('disconnected', (conn) => {
        if (server.id === conn.id) {
          callback()
        }
      })
    }

    public onSessionChanged(server: Server, callback: (server: Server, isOnline: boolean) => void) {
      this.connection.on('session_changed', (conn, online) => {
        if (server.id === conn.id) {
          const response = plainToClass(Server, conn)
          callback(response, online)
        }
      })
    }

    public onServerDeleted(server: Server, callback: () => void) {
      this.connection.on('server_deleted', (conn) => {
        if (server.id === conn.id) {
          callback()
        }
      })
    }

    public onServerItems(server: Server, callback: (items: ServerItem[]) => void) {
      this.connection.on('server_items', (conn, response) => {
        if (server.id === conn.id) {
          const itemObjects = response.items as object[]
          const items = plainToClass(ServerItem, itemObjects)
          items.forEach(item => { item.init() })
          callback(items)
        }
      })
    }

    public onServerLogs(server: Server, callback: (logs: LogMessage[]) => void) {
      this.connection.on('server_logs', (conn, response) => {
        if (server.id === conn.id) {
          callback(response.logs)
        }
      })
    }

    public onBackupMessage(server: Server, callback: (backupMessage: CloudMessagWrapper) => void) {
      this.connection.on('backup_message', (conn, backupMessage) => {
        if (server.id === conn.id) {
          callback(plainToClass(CloudMessagWrapper, backupMessage))
        }
      })
    }

    public onRestoreBackupMessage(server: Server, callback: (restoreBackupMessage: CloudMessagWrapper) => void) {
      this.connection.on('restore_backup_message', (conn, restoreBackupMessageWrapper) => {
        if (server.id === conn.id) {
          callback(plainToClass(CloudMessagWrapper, restoreBackupMessageWrapper))
        }
      })
    }

    public onServerActivity(server: Server, callback: (serverActivity: ServerActivity) => void) {
      this.connection.on('server_activity', (conn, serverActivity) => {
        if (server.id === conn.id) {
          callback(plainToClass(ServerActivity, serverActivity))
        }
      })
    }

    public onServerActivityUpdate(server: Server, callback: (serverActivity: ServerActivity) => void) {
      this.connection.on('server_activity_update', (conn, serverActivity) => {
        if (server.id === conn.id) {
          callback(plainToClass(ServerActivity, serverActivity))
        }
      })
    }

    public onAvailableRemoteServices(server: Server, callback: (services: string[]) => void) {
      this.connection.on('available_remote_services', (conn, response) => {
        if (server.id === conn.id) {
          callback(response.services)
        }
      })
    }

    public onServerInfo(server: Server, callback: (serverInfo: ServerInfo) => void) {
      this.connection.on('server_info', (conn, serverInfo) => {
        if (server.id === conn.id) {
          callback(plainToClass(ServerInfo, serverInfo))
        }
      })
    }

    public onScheduleChanged(server: Server, callback: () => void) {
      this.connection.on('schedule_changed', (conn) => {
        if (server.id === conn.id) {
          callback()
        }
      })
    }

    public isConnected(): boolean {
      return this.connection && this.connection.state && this.connection.state === HubConnectionState.Connected
    }

    public unregisterOnSessionChanged() {
      this.connection.off('session_changed')
    }

    public unregisterOnServerDeleted() {
      this.connection.off('server_deleted')
    }

    public unregisterOnServerItems() {
      this.connection.off('server_items')
    }

    public unregisterOnServerLog() {
      this.connection.off('server_logs')
    }

    public unregisterOnBackupMessage() {
      this.connection.off('backup_message')
    }

    public unregisterOnServerInfo() {
      this.connection.off('server_info')
    }

    public unregisteronScheduleChanged() {
      this.connection.off('schedule_changed')
    }

    private start() {
      this.startedPromise = this.connection.start()
        .catch(() => {
          return new Promise((resolve, reject) =>
            setTimeout(() =>
              this.start().then(resolve).catch(reject),
            5000)
          )
        })
      return this.startedPromise
    }
}

import { Server } from '@/models/server'
import { Transform, Type } from 'class-transformer'
import moment, { Moment } from 'moment'
import { Account } from '@/models/account'

export class ServerActivity {
    public id: number

    public message: string

    @Transform((value) => moment.tz(value, 'UTC'))
    public timestamp: Moment

    public type: string

    @Type(() => Account)
    public account: Account

    @Type(() => Server)
    public server: Server

    @Transform((value) => moment.tz(value, 'UTC'))
    public notificationSent: Moment
}


import { TableHeader } from '@/models/table-header'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BlobFile } from '@/models/blob-file'
import { Server } from '@/models/server'
import { ScheduledJob } from '@/models/scheduled-job'
import moment from 'moment'
import { BackupRestoreType } from '@/models/backupRestore/backup-restore-type'

@Component
export default class BackupsTable extends Vue {
        private backupRestoreTypeEnum = BackupRestoreType
        private files: BlobFile[] = []
        private search = ''
        private showDeleteDialog = false
        private showRestoreDialog = false
        private backupRestoreType = BackupRestoreType.New
        private selectedFile: BlobFile = null
        private isDeleting = false
        private scheduleMessage = ''
        private backupConfigured = false
        private activeMsg = ''

        headers: TableHeader[] = [
          { text: this.$t('table.backups.workspace'), value: 'workspace' },
          { text: this.$t('table.backups.created'), value: 'created' },
          { text: this.$t('table.backups.uploaded'), value: 'uploaded' },
          { text: this.$t('table.backups.size'), value: 'sizeMb' },
          { text: this.$t('table.backups.logfiles'), value: 'logFileOption' },
          { text: this.$t('table.backups.nxaversions'), value: 'includeNxaVersionOutput' },
          { text: this.$t('table.backups.xlogicfiles'), value: 'includeXLogicFiles' },
          { text: this.$t('table.backups.actions'), align: 'right', value: 'actions', sortable: false }
        ]

        @Prop()
        private server: Server

        public async created() {
          await Promise.all([this.fetchSchedule(), this.queryFiles()])

          this.$signalR.onBackupMessage(this.server, () => {
            this.queryFiles()
          })
          this.$signalR.onScheduleChanged(this.server, () => {
            this.fetchSchedule()
          })
        }

        private async fetchSchedule() {
          const data = await this.$backend.get(`/servers/${this.server.id}/configured-backup-schedule`) as ScheduledJob[]
          let activeState = ''
          if (data.length > 0) {
            this.backupConfigured = true
            const backupData = data[0]
            if (backupData.isActive) {
              this.scheduleMessage = moment.utc(backupData.nextExecution).local().format('DD.MM.YYYY HH:mm')
              activeState = this.$t('backup.schedule.backup_plan.active') as string
            } else {
              activeState = this.$t('backup.schedule.backup_plan.inactive') as string
              this.scheduleMessage = undefined
            }
          } else {
            this.scheduleMessage = this.$t('backup.schedule.not_configured') as string
            return
          }

          this.activeMsg = this.$t('backup.schedule.backup_plan.msg', [activeState]) as string
        }

        private async queryFiles() {
          this.files = await this.$backend.list(`/servers/${this.server.id}/list-backups`, BlobFile)
        }

        private async downloadFile(item: BlobFile) {
          try {
            const response = await this.$http.post(`/servers/${this.server.id}/request-backup-url`, `"${item.path}"`)
            const sasToken = response.data
            window.location.assign(sasToken)
          } catch (ex) {
            // tslint:disable-next-line
            console.error(ex)
          }
        }

        private async restoreBackup(item: BlobFile) {
          this.showRestoreDialog = false
          try {
            await this.$http.post(`/servers/${this.server.id}/restore-backup/${this.backupRestoreType}`, `"${item.path}"`)
          } catch (ex) {
            // tslint:disable-next-line
            console.error(ex)
          }
        }

        private restoreClicked(item: BlobFile) {
          this.selectedFile = item
          this.showRestoreDialog = true
        }

        private async deleteFile(path: string) {
          this.isDeleting = true
          try {
            await this.$http.delete(`/storage/${encodeURIComponent(path)}`)
            this.showDeleteDialog = false
            await this.queryFiles()
          } catch (ex) {
            // tslint:disable-next-line
            console.error(ex)
          }
          this.isDeleting = false
        }

        private deleteClicked(item: BlobFile) {
          this.selectedFile = item
          this.showDeleteDialog = true
        }
}

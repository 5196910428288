import { Server } from '@/models/server'
import { Account } from '@/models/account'

export class AccountServer {
  public id: number
  public serverId: number
  public accountId: number
  public server: Server
  public account: Account
}


import BackendStatusSnackbar from '@/components/BackendStatusSnackbar.vue'
import MessageSnackbar from '@/components/MessageSnackbar.vue'
import Navigation from '@/components/Navigation.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    MessageSnackbar,
    BackendStatusSnackbar,
    Navigation
  }
})
export default class Home extends Vue {
}

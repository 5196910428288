
import DeleteConfirmationDialog from '@/components/DeleteConfirmationDialog.vue'
import NxaAlert from '@/components/NxaAlert.vue'
import NxaServerStatus from '@/components/NxaServerStatus.vue'
import { Group } from '@/models/group'
import { Server } from '@/models/server'
import { TableHeader } from '@/models/table-header'
import { getOrgSlug } from '@/services/Environment'
import GroupDialog from '@/views/Servers/GroupDialog.vue'
import ServerRegistration from '@/views/Servers/ServerRegistration.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DeleteConfirmationDialog,
    GroupDialog,
    NxaServerStatus,
    NxaAlert,
    ServerRegistration
  }
})
export default class GroupList extends Vue {
    private groups: Group[] = []
    private search = ''

    private group: Group = new Group()
    private isLoading = false

    @Prop()
    private server: Server

    headers: TableHeader[] = [
      { text: 'Name', value: 'name' },
      { text: this.$t('group.notification_recipients'), value: 'notificationRecipients' },
      { text: '', value: 'isAssignedToServer', align: 'right' }
    ]

    public async created() {
      this.isLoading = true
      await this.loadGroups()
      this.isLoading = false
    }

    public checkBoxChanged(group: Group) {
      if (group.isAssignedToServer) {
        this.$backend.post(`/group/organization/add-server?serverId=${this.server.id}`, group)
      } else {
        this.$backend.delete(`/group/organization/add-server?serverId=${this.server.id}&groupId=${group.id}`)
      }
    }

    private async loadGroups() {
      const orgSlug = getOrgSlug()
      if (!orgSlug) {
        return
      }

      this.groups = await this.$backend.list(`/group/organization/${orgSlug}`, Group)
      if (this.server) {
        for (const group of this.groups) {
          group.isAssignedToServer = group.serverGroups.some((x) => x.serverId === this.server.id)
        }
      }
    }

    private async deleteGroup(group: Group) {
      await this.$backend.delete(`/group/${group.id}`)
      await this.loadGroups()
    }

    private rowClick(group: Group) {
      if (!this.server) {
        this.$router.push(`/${getOrgSlug()}/servers?group=${group.id}`)
      }
    }
}

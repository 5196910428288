import { Type } from 'class-transformer'
import { Organization } from './organization'
import { Server } from './server'
import { RemoteAccess } from './remote-access'

export class CloudProject {
    public id: number
    public projectId: string
    public projectName: string

    @Type(() => Server)
    public mainServer: Server

    public mainServerId: number
    public backupServerId: number

    @Type(() => Server)
    public backupServer: Server

    public mainServerWorkspace: string
    public backupServerWorkspace: string

    @Type(() => Organization)
    public organization: Organization

    public organizationId: number

    public remoteAccesses: RemoteAccess[]

    public setMainServer(server: Server) {
      this.mainServer = server
      if (server == null) {
        this.mainServerId = null
        this.mainServerWorkspace = ''
      } else {
        this.mainServerId = server.id
        this.mainServerWorkspace = server.workspaceName !== undefined ? server.workspaceName : ''
      }
    }

    public setBackupServer(server: Server) {
      this.backupServer = server
      if (server == null) {
        this.backupServerId = null
        this.backupServerWorkspace = ''
      } else {
        this.backupServerId = server.id
        this.backupServerWorkspace = server.workspaceName !== undefined ? server.workspaceName : ''
      }
    }

    public get remoteAccessCount() {
      return this.remoteAccesses.length
    }
}

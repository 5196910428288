import { getErrorMsg, showError } from '@/services/ErrorService'
import Vue from 'vue'
import 'es6-shim'
import 'reflect-metadata'
import { plainToClass } from 'class-transformer'
import { ClassType } from 'class-transformer/ClassTransformer'
import { AxiosInstance } from 'axios'

class Backend {
    private _axios

    constructor(axios: AxiosInstance) {
      this._axios = axios
    }

    public async get<T>(url: string, cls?: ClassType<T>): Promise<T> {
      const data = await this._get(url)

      if (cls == null) { return data }
      try {
        return plainToClass(cls, data)
      } catch (error) {
        Vue.prototype.messages.$emit('error', getErrorMsg())
        return null
      }
    }

    public async list<T>(url: string, cls: ClassType<T>): Promise<T[]> {
      const data = (await this._get(url)) as object[]
      if (data == null) {
        return Array<T>()
      }

      try {
        return plainToClass(cls, data)
      } catch (error) {
        Vue.prototype.messages.$emit('error', getErrorMsg())
        return Array<T>()
      }
    }

    public async post<T>(url: string, data, cls?: ClassType<T>): Promise<T> {
      const respData = await this._post(url, data)

      if (cls == null) { return respData }

      try {
        return plainToClass(cls, respData)
      } catch (error) {
        Vue.prototype.messages.$emit('error', getErrorMsg())
        return null
      }
    }

    public async postList<T>(url: string, data, cls?: ClassType<T>): Promise<T[]> {
      const respData = (await this._post(url, data)) as object[]

      if (respData == null) { return Array<T>() }

      try {
        return plainToClass(cls, respData)
      } catch (error) {
        Vue.prototype.messages.$emit('error', getErrorMsg())
        return null
      }
    }

    public async put<T>(url: string, data, cls?: ClassType<T>): Promise<T> {
      const respData = await this._put(url, data)

      if (cls == null) { return respData }

      try {
        return plainToClass(cls, respData)
      } catch (error) {
        Vue.prototype.messages.$emit('error', getErrorMsg())
        return null
      }
    }

    public async delete(url: string): Promise<void> {
      try {
        await this._axios.delete(url)
      } catch (error) {
        this.handleResponseStatus(error.response)
      }
    }

    private handleResponseStatus(response) {
      if (response === undefined) return

      switch (response.status) {
        case 400:
        case 500:
          showError(this.extractErrorCode(response.data))
          break

        case 403:
          Vue.prototype.messages.$emit('fatal', 'insufficient privileges')
          break

        default:
          showError()
      }
    }

    private extractErrorCode(responseData: string) {
      return responseData.indexOf(': ') > 0
        ? responseData.substring(responseData.indexOf(': ') + 2, responseData.indexOf('\n'))
        : responseData
    }

    private async _get(url: string) {
      try {
        const response = await this._axios.get(url)
        return response.data
      } catch (error) {
        this.handleResponseStatus(error.response)
        return null
      }
    }

    private async _post(url: string, data) {
      try {
        const response = await this._axios.post(url, data)
        return response.data
      } catch (error) {
        this.handleResponseStatus(error.response)
        return null
      }
    }

    private async _put(url: string, data) {
      try {
        const response = await this._axios.put(url, data)
        return response.data
      } catch (error) {
        this.handleResponseStatus(error.response)
        return null
      }
    }
}

declare module 'vue/types/vue' {
  interface Vue {
    $backend: Backend;
  }
}

export function BackendPlugin(vue: typeof Vue): void {
  vue.prototype.$backend = new Backend(vue.prototype.$http)
}

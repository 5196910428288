
import EditOrganization from '@/views/management/EditOrganization.vue'
import { Component, Vue } from 'vue-property-decorator'
import { getOrgSlug } from '@/services/Environment'
import { Organization } from '@/models/organization'

@Component({
  components: { EditOrganization }
})
export default class OrganizationOverview extends Vue {
  private organization: Organization = null

  public async mounted() {
    this.organization = await this.$backend.get(`/organizations/slug/${getOrgSlug()}`, Organization)
  }

  public organizationChanged(organization: Organization) {
    Object.assign(this.organization, organization)
  }
}


import DeleteConfirmationDialog from '@/components/DeleteConfirmationDialog.vue'
import { TableHeader } from '@/models/table-header'
import { Component, Vue } from 'vue-property-decorator'
import { RemoteAccess } from '@/models/remote-access'
import { CloudProject } from '@/models/cloud-project'
import EditRemoteAccess from './EditRemoteAcces.vue'

@Component({
  components: {
    EditRemoteAccess,
    DeleteConfirmationDialog
  }
})

export default class RemoteAccesList extends Vue {
    private remoteAccesses: RemoteAccess[] = []
    private search = ''
    private showEditRemoteAccesDialog = false
    private isLoading = false
    private remoteAccess: RemoteAccess = new RemoteAccess()
    private cloudProject: CloudProject = new CloudProject()

    headers: TableHeader[] = [
      { text: this.$t('remote-access.name'), value: 'name' },
      { text: this.$t('remote-access.type'), value: 'type' },
      { text: this.$t('remote-access.active'), value: 'active' },
      { text: this.$t('remote-access.link'), value: 'link' },
      { text: this.$t('remote-access.allowed-devices'), value: 'allowedDevices' },
      { text: '', value: 'id', align: 'right' }
    ]

    public async created() {
      try {
        this.loadRemoteAccesses()
        this.cloudProject = await this.$backend.get(`/cloudprojects/${this.$route.params.projectId}`, CloudProject)
      } catch (ex) {
        // tslint:disable-next-line: no-console
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    }

    private editClicked(remoteAccess: RemoteAccess) {
      this.remoteAccess = remoteAccess
      this.showEditRemoteAccesDialog = true
    }

    private createClicked() {
      this.remoteAccess = new RemoteAccess()
      this.remoteAccess.active = true
      this.remoteAccess.type = 'visu'
      this.showEditRemoteAccesDialog = true
    }

    private async deleteRemoteAccess(remoteAccess: RemoteAccess) {
      await this.$backend.delete(`/remoteaccess/${remoteAccess.id}`)
      await this.loadRemoteAccesses()
    }

    private async loadRemoteAccesses() {
      this.remoteAccesses = await this.$backend.list(`/remoteaccess/project/${this.$route.params.projectId}`, RemoteAccess)
    }

    private copy(ref: string) {
      (this.$refs[ref] as HTMLElement).focus()
      document.execCommand('copy')
    }
}

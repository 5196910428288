
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Server } from '@/models/server'
import { LogMessage } from '@/models/log-message'
import {
  DxColumn,
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxHeaderFilter,
  DxMasterDetail,
  DxScrolling
} from 'devextreme-vue/data-grid'

@Component({
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxMasterDetail,
    DxHeaderFilter,
    DxFilterRow,
    DxExport
  }
})
export default class ServerLogsTable extends Vue {
  @Prop()
  public server: Server

  private logs: LogMessage[] = []
  private autoUpdate = true
  private intervalHandle

  public mounted() {
    this.server.logs = []
    this._requestItems(1000)
    this.intervalHandle = setInterval(this.requestItems, 5000)
  }

  public destroyed() {
    clearInterval(this.intervalHandle)
  }

  @Watch('server', { deep: true })
  private onServerLogsUpdated() {
    this.logs = this.server.logs
  }

  private requestItems() {
    if (this.autoUpdate) {
      this._requestItems(100)
    }
  }

  private _requestItems(items: number) {
    this.$http.post(`/servers/${this.server.id}/server-logs`, '"' + items + '"')
  }

  private exportData(inline = false) {
    const header = Object.keys(this.logs[0])
    const csv = this.logs
      .map((row) =>
        header
          .map((fieldName) => row[fieldName] === null ? '' : row[fieldName])
          .join(';')
      )
      .join('\r\n')

    if (inline) {
      const tab = window.open('about:blank', '_blank')
      tab.document.write('<pre>' + csv + '</pre>')
      tab.document.close()
    } else {
      const hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:attachment/text,' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'log.txt'
      hiddenElement.click()
      document.body.removeChild(hiddenElement)
    }
  }

  private rowPrepared(e) {
    if (e.data && e.data.logType === 'ERROR') {
      e.rowElement.classList.add('lighten-3')
      e.rowElement.classList.add('red')
    } else if (
      e.data &&
      (e.data.logType === 'WARNING' || e.data.logType === 'WARN')
    ) {
      e.rowElement.classList.add('lighten-3')
      e.rowElement.classList.add('amber')
    }
  }
}

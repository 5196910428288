
import { Component, Vue } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'

@Component({})
export default class RemoteWeb extends Vue {
  private isLoading = true
  private remoteUrl: string
  private windowClosedInterval: number
  private remoteWindow: Window
  private tries = 0

  public setUrl(remoteUrl: string) {
    this.isLoading = true
    this.tries = 0
    this.remoteUrl = remoteUrl
    this.urlChanged()
  }

  private async urlChanged() {
    if (this.remoteUrl === '') {
      return
    }

    try {
      const result: AxiosResponse = await this.$http.get(this.remoteUrl)
      if (result.status === 200) {
        this.openWindow()
        return
      }
    } catch (e) {
      // can be ignored (nginx throws CORS error if port is not yet forwarded)
    }

    // TODO fix me! temp fix until webvisu implements preflight requests
    if (this.tries > 3) {
      this.openWindow()
      return
    }

    // connection not ready yet, try again later
    this.tries = this.tries + 1
    setTimeout(this.urlChanged, 1000)
  }

  private openWindow() {
    this.isLoading = false
    const remoteWindow = window.open(this.remoteUrl)
    this.monitorWindow(remoteWindow)
  }

  private monitorWindow(remoteWindow: Window) {
    this.remoteWindow = remoteWindow
    this.windowClosedInterval = window.setInterval(() => {
      try {
        if (remoteWindow.closed) {
          clearInterval(this.windowClosedInterval)
          this.$emit('closed')
        }
      } catch (error) {
        // can be ignored: cross origin exception
      }
    }, 1000)
  }
}

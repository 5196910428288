
import { ItemStatus } from '@/models/item-status'
import { Server } from '@/models/server'
import { ServerItem } from '@/models/server-item'
import { ServerStatus } from '@/models/server-status'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ItemStatusLabel extends Vue {
    private status: ItemStatus = ItemStatus.Unknown

    @Prop()
    private server: Server

    @Prop()
    private itemKey: string

    @Prop()
    private label?: string

    public data() {
      return {
        ItemStatus,
        ServerStatus
      }
    }

    public mounted() {
      this.setStatus()
    }

    @Watch('server', { deep: true })
    public onServerChanged() {
      this.setStatus()
    }

    private setStatus() {
      this.status = this.server.itemStatus(this.itemKey)
      if (this.status !== ItemStatus.Ok && this.server.status === ServerStatus.Standby && this.itemKey === ServerItem.databaseKey) {
        this.status = ItemStatus.Standby
      }
    }
}

import { JobConfig } from './job-config'

export class ScheduledJob {
  public id: number
  public cronExpression: string
  public isActive: boolean
  public serverId: number
  public jobConfig: JobConfig
  public jobType: number
  public nextExecution: string
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { Server } from '@/models/server'
import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapSymbolLayer
} from 'vue-azure-maps'

@Component({
  components: {
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer
  }
})
export default class ServerDetailMap extends Vue {
  @Prop()
  private server: Server

  private point: {
    id: number;
    longitude: number;
    latitude: number;
  } = { id: null, longitude: 0, latitude: 0 }

  private symbolLayerOptions = {
    iconOptions: {
      ignorePlacement: true,
      allowOverlap: true,
      image: 'pin-red'
    }
  }

  private async created() {
    if (this.server.position) {
      this.point = {
        id: this.server.id,
        latitude: +this.server.position.split(', ')[0],
        longitude: +this.server.position.split(', ')[1]
      }
    }
  }
}
